<template>
  <centered-page>
    <v-card elevation-2 width="50%" class="mx-auto">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Réinitialiser le mot de passe</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <p>
          Saisissez l'adresse email associée à votre compte Officine afin de
          recevoir les intructions pour modifier votre mot de passe.
        </p>

        <v-form ref="form" v-model="isValid" :lazy-validation="lazy">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Adresse email"
            outlined
            required
          ></v-text-field>

          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{}">
              <v-btn
                color="primary"
                class="mr-4"
                @click="validateAction"
                :disabled="!isValid"
              >
                Continuer
              </v-btn>
            </template>

            <v-card>
              <v-card-title
                class="headline red lighten-1 white--text"
                v-if="messageKo"
              >
                {{ messageKo }}
              </v-card-title>

              <v-card-title
                class="headline primary white--text"
                v-if="messageOk"
              >
                {{ messageOk }}
              </v-card-title>
              <br />

              <v-card-text v-if="messageKo">
                L'adresse email que vous avez renseignée n'existe pas. Veuillez
                vérifier que vous avez rentré le bon email et réessayez.
              </v-card-text>

              <v-card-text v-if="messageOk">
                Un email à été envoyé à l'adresse <b> {{ email }} </b>. Vous y
                trouverez un lien pour réinitialiser le mot de passe.
                <br />
                <br />
                Si vous ne recevez pas cette email dans votre boite de
                réception, n'oubliez pas de vérifier dans vos spams. Sinon
                contacez un administrateur.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="messageKo" text outlined @click="dialog = false">
                  Fermer
                </v-btn>
                <v-btn v-if="messageOk" text outlined @click="done">
                  Continuer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </v-card-text>
    </v-card>
  </centered-page>
</template>

<script>
import PasswordService from '../../../services/password.service'

export default {
  data: () => ({
    dialog: false,
    isValid: true,
    email: '',
    lazy: '',
    messageOk: '',
    messageKo: '',
    alert: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ]
  }),

  components: {
    'centered-page': () => import('../../../core/CenteredFullPage'),
    'app-title': () => import('../../../core/Title'),
    'app-btn': () => import('../../../core/BasicBtn')
  },

  methods: {
    validateAction() {
      if (this.$refs.form.validate()) {
        PasswordService.putEmail(this.email)
          .then(response => {
            if (response.status == 200) {
              this.messageOk = response.data.message
              this.messageKo = ''
              this.dialog = true
            } else {
              this.messageKo = response.data.message
              this.messageOk = ''
              this.dialog = true
            }
          })
          .catch()
      }
    },
    done() {
      this.dialog = false
      this.$router.push('/login')
    }
  }
}
</script>
