<template>
  <div>
    <p class="font-weight-medium mt-4 mb-2">
      Quelle a été la durée de la prise en charge (en minutes) ?
    </p>
    <v-text-field
      v-model="formulaire.durationTakeCare"
      label="Durée de prise en charge"
      suffix="minutes"
      outlined
      dense
      type="number"
      append-outer-icon="mdi-minus-box"
      prepend-icon="mdi-plus-box"
      @click:append-outer="formulaire.durationTakeCare--"
      @click:prepend="formulaire.durationTakeCare++"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'durationTakeCareForm',
  props: {
    formulaire: { type: Object, required: true }
  },
  data: function() {
    return {
      duration: 0
    }
  }
}
</script>
