<template>
  <v-container fluid>
    <div class="pa-10 mx-auto">

      <h1>Mentions d’information pour le GRAND EST</h1>
      <div class="text-justify ">
        <p>L’URPS Pharmaciens Grand Est, représentée par Christophe WILCKE est le responsable de traitement, au sens de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux Fichiers et aux Libertés modifiée (« Loi Informatique et Liberté ») de vos données à caractère personnel collectées et traitées dans le cadre de la gestion de votre participation à cette enquête.</p>
        <p>Le traitement de vos données à caractère personnel ainsi mis en œuvre est fondé sur l’intérêt légitime de l’URPS Pharmaciens Grand Est, dont vous êtes adhérent, à mener une enquête sur les demandes de soins non programmées à l’officine et ainsi dresser un état des lieux de la prise en charge de ces soins au sein des officines de la région Grand Est.</p>

        <p>Les catégories de données traitées sont (i) vos données d’identification et (ii) les données liées à votre pratique professionnelle.</p>

        <p>En aucun cas les résultats de l’enquête ne sont reliés à un élément d’identification.</p>

        <p>Vos données à caractère personnel sont conservées pendant 2 ans et enfin, elles seront archivées.</p>

        <p>Vos données sont destinées à l’URPS PHARMACIENS GRAND EST, à TOUS POUR LA SANTE et aux sous-traitants auxquels l’URPS Pharmaciens Grand Est fait appel pour toute opération technique portant sur vos données dans le cadre de garanties suffisantes au titre de la mise en œuvre de mesures techniques et organisationnelles appropriées, en particulier en termes de confidentialité et de sécurité. Des dispositifs renforcés en termes de sécurité sont mis en œuvre afin de permettre une collecte et un traitement de vos données dans les conditions garantissant la confidentialité, leur intégrité et de manière plus générale leur sécurité dans le respect des dispositions de la loi Informatique et Libertés.</p>
        <p>Vous disposez d’un droit d’accès, de rectification, d’opposition pour des motifs légitimes sur vos données à caractère personnel ainsi que du droit de définir des directives générales relatives à la conservations, à l’effacement, et à la communication de vos données à caractère personnel après votre décès qui peuvent être enregistrées auprès d’un tiers de confiance numérique certifié par la CNIL et de directives particulières qui peuvent être enregistrées auprès de contact@touspourlasante.tv et qui font l’objet de votre consentement spécifique à ce titre le cas échéant. Vous disposez en outre du droit de solliciter la limitation du traitement, d’un droit à l’oubli et à l’effacement numérique, d’un droit à la portabilité de vos données, du droit d’introduire une réclamation auprès de la CNIL.</p>

        <p>Pour exercer ces droits, vous pouvez contacter directement TousPourLaSanté par courrier en écrivant à TOUS POUR LA SANTE – 104 Boulevard Malesherbes 75017 Paris ou par e-mail à l’adresse
          <a
            text
            href="mailto: contact@touspourlasante.tv"
          > contact@touspourlasante.tv
          </a>
        </p>
      </div>

      <h2>Mentions légales</h2>
      <p>Identification</p>
      <p>URPS PHARMACIENS GRAND EST</p>
      <p>18 Quai Claude le Lorrain
        54000 NANCY</p>
      <p>N° de Tél : 09 53 73 45 34</p>
      <a
        text
        href="mailto:secretariat@urpspharmaciensgrandest.fr"
      > secretariat@urpspharmaciensgrandest.fr
      </a>
      <p>Association loi 1901 SIRET n°81876506700025</p>
      <p>Président : Christophe WILCKE</p>
    </div>

  </v-container>
</template>

<script>
export default {
  name: 'Mention',
}
</script>