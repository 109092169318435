<template>
  <div class="py-0">
    <p class="font-weight-medium mt-4 mb-1">Date de prise en charge</p>

    <v-radio-group
      class="mt-1"
      v-model="pickupDate.type"
      :rules="rulesReq(true)"
    >
      <div>
        <v-radio value="inlist" label="Planning fourni par l’URPS"></v-radio>
        <div>
          <v-expand-transition>
            <div v-show="pickupDate.type == 'inlist'">
              <v-select
                v-model="dateString"
                :items="possibleDates"
                item-text="label"
                item-value="value"
                label="Précisez la date"
                dense
                :rules="rulesReqInlist(pickupDate.type == 'inlist')"
                outlined
                class="pl-3 ma-0"
                @change="dateFormatted = formatDate(dateString)"
              ></v-select>
            </div>
          </v-expand-transition>
        </div>

        <v-radio value="innight" label="Garde de nuit en semaine"></v-radio>
        <div>
          <v-expand-transition>
            <div v-show="pickupDate.type == 'innight'">
              <v-text-field
                v-model="dateFormatted"
                v-mask="'##/##/####'"
                hint="Au format JJ/MM/AAAA"
                persistent-hint
                label="Précisez la date"
                outlined
                dense
                :rules="rulesReq(pickupDate.type == 'innight')"
                class="pl-3 ma-0"
                @change="dateString = parseDate(dateFormatted)"
              ></v-text-field>
            </div>
          </v-expand-transition>
        </div>
        <v-radio
          value="inholiday"
          label="Garde dimanche / jour férié"
        ></v-radio>
        <div>
          <v-expand-transition>
            <div v-show="pickupDate.type == 'inholiday'">
              <v-text-field
                v-model="dateFormatted"
                v-mask="'##/##/####'"
                hint="Au format JJ/MM/AAAA"
                persistent-hint
                label="Précisez la date"
                outlined
                dense
                :rules="rulesReq(pickupDate.type == 'inholiday')"
                class="pl-3 ma-0"
                @change="dateString = parseDate(dateFormatted)"
              ></v-text-field>
            </div>
          </v-expand-transition>
        </div>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import FormulaireService from '../../../services/formulaire.service'

export default {
  name: 'pickupDateForm',

  props: {
    pickupDate: { type: Object, required: true }
  },

  data: () => ({
    possibleDates: [{ label: '19/11/2020', value: '2020-11-19' }],
    dateString: '',
    dateFormatted: ''
  }),

  mounted: function() {
    // Initialisation des valeurs des champs
    this.dateString = this.transform(this.pickupDate.date)
    this.dateFormatted = this.fullTransform(this.pickupDate.date)

    // Récupération des dates des events
    var events = []

    FormulaireService.getSessionDates()
      .then(response => {
        response.data.sessionDates.forEach(date => {
          let currentDate = date.start.split(' ')[0]
          events.push({
            label: this.formatDate(currentDate),
            value: currentDate
          })
        })
        this.possibleDates = events
      })
      .catch()
  },

  watch: {
    pickupDate: function() {
      this.dateString = this.transform(pickupDate.date)
    },
    dateString: function() {
      this.pickupDate.date = this.reverseTransform(this.dateString)
    }
  },

  methods: {
    rulesReq(isActive) {
      return isActive
        ? [v => !!v || 'Veuillez sélectionner une valeur']
        : [() => true]
    },
    rulesReqInlist(isActive) {
      return isActive
        ? [
            v => {
              // Check if pickupDate is in the list (and selected)
              let options = this.possibleDates.map(a => a.value)
              return (
                options.includes(v) ||
                'Veuillez sélectionner une date dans la liste du planning'
              )
            }
          ]
        : [() => true]
    },
    /** From YYYY-MM-DD to DD/MM/YYYY */
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
    /** From DD/MM/YYYY to  YYYY-MM-DD */
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /** From Date to YYYY-MM-DD */
    transform(date) {
      if (!date) return null

      if (typeof date == 'string') {
        date = new Date(date)
      }

      let month = date.getMonth() + 1
      let day = date.getDate()

      return `${date.getFullYear()}-${month
        .toString()
        .padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    },
    /** From YYYY-MM-DD to Date*/
    reverseTransform(date) {
      if (!date) return null

      return new Date(date)
    },
    /** From Date to DD/MM/YYY */
    fullTransform(date) {
      if (!date) return null

      return this.formatDate(this.transform(date))
    }
  },
  directives: { mask }
}
</script>

<style scoped>
/deep/ .v-messages {
  min-height: 0 !important;
}
</style>
