<template>
  <v-container class="d-flex justify-center px-16">
    <v-row justify="center">
      <div v-for="(info, index) in infos" :key="index">
        <v-col
          class="d-flex flex-column px-12"
          :class="
            Number.isInteger(info.subtitle)
              ? 'align-center'
              : 'align-self-start'
          "
        >
          <p class="overline font-weight-bold grey--text text--darken-3 mb-1">
            {{ info.title }}
          </p>
          <p
            :class="
              Number.isInteger(info.subtitle)
                ? 'font-weight-medium primary--text text-h6'
                : 'text-body-2 grey--text align-self-start'
            "
          >
            {{ info.subtitle }}
          </p>
        </v-col>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import FormulaireService from '../../../services/formulaire.service'

export default {
  name: 'InlineInfo',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      infos: [
        {
          title: "Date d'inscription",
          subtitle: this.formatDate(this.user.createdAt)
        },
        {
          title: 'Formulaire remplis',
          subtitle: 0
        },
        {
          title: 'Sessions',
          subtitle: 0
        },
        {
          title: 'Prochaine Session',
          subtitle: 0
        }
      ]
    }
  },
  mounted() {
    this.getSessionDates()
    this.getSessions()
    this.getMyForms()
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('fr-FR', {
        month: 'long',
        year: 'numeric',
        day: 'numeric'
      })
    },
    getNextSession(sessionDates) {
      let deltaArr = []
      for (let date of sessionDates) {
        deltaArr.push(new Date(date.start) - Date.now())
      }
      let timeUntilNextSession = Math.min(
        ...deltaArr.filter(x => {
          return x > 0
        })
      )
      return this.formatDate(
        sessionDates[deltaArr.indexOf(timeUntilNextSession)].start
      )
    },
    getSessionDates() {
      FormulaireService.getSessionDates()
        .then(response => {
          this.infos[3].subtitle = this.getNextSession(
            response.data.sessionDates
          )
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSessions() {
      FormulaireService.countSessionByFiness()
        .then(response => {
          this.infos[2].subtitle = response.data.countSession
        })
        .catch(err => {
          console.log(err)
        })
    },
    getMyForms() {
      FormulaireService.countPatientsInfoByFiness()
        .then(response => {
          this.infos[1].subtitle = response.data.patientsNumberFiness
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
