import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import Vuex from 'vuex';
import 'leaflet/dist/leaflet.css';
import vuetify from './plugins/vuetify';
import Toasted from 'vue-toasted';
import VueApexCharts from 'vue-apexcharts';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;
Vue.use(Toasted)
Vue.use(Vuex);

Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  
  render: h => h(App)
}).$mount('#app');
