<template>
  <v-container fluid class="pa-0">
    <AvatarInfo :user="currentUser" />
    <InlineInfo :user="currentUser" />
    <Informations :user="currentUser" />
    <Actions :user="currentUser" />
  </v-container>
</template>

<script>
import User from '../../../models/user'
import UserService from '../../../services/user.service'
import AvatarInfo from '../components/AvatarInfo'
import InlineInfo from '../components/InlineInfo'
import Informations from '../components/Informations'
import Actions from '../components/Actions'

export default {
  name: 'Profile',
  components: {
    Informations,
    AvatarInfo,
    InlineInfo,
    Actions
  },
  data() {
    return {
      user: new User(null)
    }
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.user) return this.$store.state.auth.user
      else return this.user
    }
  }
}
</script>
