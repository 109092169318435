export default class User {
  constructor(
    finess,
    situation,
    type,
    postal,
    collaboration,
    groupment,
    teamSize,
    email,
    privacyLocal,
    proposedService,
    frequency,
    crisisImpact,
    age,
    sex,
    formationUrgency,
    formationPatient,
    timeOfFormation,
    necessaryTypeOfFormation,
    favorableForRecognition,
    password
  ) {
    // A.
    this.finess = finess
    this.postal = postal
    this.email = email
    this.password = password
    this.name = ''

    // B.
    this.situation = {
      content: situation
    }
    this.type = {
      content: '',
      precision: ''
    }
    this.collaboration = {
      response: '',
      subLevel: {
        content: []
      }
    }
    this.groupment = {
      content: groupment
    }
    this.teamSize = {
      licensedPharmacists: '',
      student: '',
      preparator: '',
      adjointsPharmacists: ''
    }
    this.privacyLocal = {
      content: '',
      subLevel: {
        content: []
      }
    }
    this.proposedService = {
      content: [],
      precision: '',

      pharmaceuticalInterviews: {
        content: []
      },
      trodUtilisation: {
        content: []
      },
      constantsCheckboxes: {
        content: []
      }
    }
    this.frequency = {
      content: frequency
    }
    this.crisisImpact = {
      content: [],
      covidLinked: {
        content: '',
        subLevelYes: {
          content: []
        }
      },
      notCovidLinked: {
        content: '',
        subLevelYes: {
          content: []
        },
        subLevelNo: {
          content: []
        }
      }
    }

    // C.
    this.age = {
      content: age
    }
    this.sex = {
      content: sex
    }
    this.formationUrgency = {
      response: '',
      subLevelYes: {
        content: []
      }
    }
    this.formationPatientOrientation = {
      content: formationPatient
    }

    this.timeOfFormationAfgsu = { content: '' }
    this.timeOfFormationOrientation = { content: '' }

    this.necessaryTypeOfFormation = {
      content: [],
      precision: ''
    }
    this.favorableForRecognition = { content: favorableForRecognition }
  }
}
