<template>
  <centered-page>
    <v-card elevation-2 width="50%" class="mx-auto">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Changer le mot de passe</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-subtitle>
        Choisissez un nouveau mot de passe. Le mot de passe doit contenir au
        moins 8 caractères, un chiffre, une lettre majuscule et une lettre
        minuscule
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="isValid" :lazy-validation="lazy">
          <v-text-field
            v-model="password"
            :rules="[rules.required]"
            type="password"
            outlined
            label="Nouveau mot de passe"
            required
          ></v-text-field>
          <v-text-field
            v-model="password1"
            :rules="[rules.required, passwordConfirmationRule]"
            type="password"
            outlined
            label="Confirmer mot de passe"
            required
          ></v-text-field>

          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{}">
              <v-btn
                color="primary"
                class="mr-4"
                @click="validate"
                :disabled="!isValid"
              >
                Réinitialiser le mot de passe
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline red lighten-1 white--text" v-if="messageKo">
                {{ messageKo }}
              </v-card-title>

              <v-card-title class="headline primary white--text" v-if="messageOk">
                {{ messageOk }}
              </v-card-title>
              <br />

              <v-card-text v-if="messageKo">
                Le token fourni pour changer votre mot de passe est invalide ou
                il a tout simplement expiré.
                <br />
                <br />
                Cliquez sur réesayer pour envoyer un autre mail afin de changer
                votre mot de passe.
              </v-card-text>

              <v-card-text v-if="messageOk">
                Félicitations, votre mot de passe a bien été changé.<br />
                Cliquez sur continuer pour vous connecter.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="messageKo" text outlined @click="retry">
                  Réessayer
                </v-btn>
                <v-btn v-if="messageOk" text outlined @click="done">
                  Continuer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </v-card-text>
    </v-card>
  </centered-page>
</template>

<script>
import PasswordService from '../../../services/password.service'
export default {
  data: () => ({
    dialog: false,
    isValid: true,
    password: '',
    password1: '',
    lazy: '',
    messageOk: '',
    messageKo: '',
    alert: '',
    token: '',
    rules: {
      required: value => !!value || 'Champ requis.'
    }
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    passwordConfirmationRule() {
      return () =>
        this.password === this.password1 ||
        'Les mots de passe doivent correspondre'
    }
  },

  components: {
    'centered-page': () => import('../../../core/CenteredFullPage')
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        PasswordService.putPassword(this.password, this.$route.params.token)
          .then(response => {
            if (response.status == 200) {
              this.messageOk = response.data.message
              this.messageKo = ''
              this.dialog = true
            } else {
              this.messageKo = response.data.message
              this.messageOk = ''
              this.dialog = true
            }
          })
          .catch()
      }
    },
    retry() {
      this.dialog = false
      this.$router.push('/resetpassword')
    },
    done() {
      this.dialog = false
      this.$router.push('/login')
    }
  }
}
</script>
