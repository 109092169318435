<template>
  <div>
    <radioForm
      title="Avez-vous partagé/ allez-vous partager une information concernant cette visite du patient à l’officine, avec un/ plusieurs autre(s) professionnel(s) de santé ?"
      :options="sharedInfoOptions"
      :model="sharedInfo"
      :level="0"
    ></radioForm>
  </div>
</template>

<script>
export default {
  name: 'sharedInfoForm',
  props: {
    sharedInfo: { type: Object, required: true }
  },
  components: {
    radioForm: () => import('../../../core/formFactory/radioForm')
  },
  data: function() {
    return {
      sharedInfoOptions: [
        {
          value: true,
          label: 'Oui',
          sublevel: {
            modelname: 'subLevel',
            type: 'checkbox',
            options: [
              {
                value: 'liberal',
                label: 'Professionnels de santé libéraux',
                sublevel: {
                  modelname: 'subLevelI',
                  type: 'checkbox',
                  options: [
                    {
                      value: 'generalPractitioner',
                      label: 'Médecin traitant'
                    },
                    {
                      value: 'otherDoctor',
                      label: 'Autre médecin à qui vous avez adressé le patient'
                    },
                    {
                      value: 'specialist',
                      label: 'Médecin spécialiste'
                    },
                    {
                      value: 'nurse',
                      label: 'Infirmier'
                    },
                    {
                      value: 'kine',
                      label: 'Kinésithérapeute'
                    },
                    {
                      value: 'biologicalLab',
                      label: 'Laboratoire de Biologie'
                    },
                    {
                      value: 'midwife',
                      label: 'Sage-femme'
                    },
                    {
                      value: 'dentalChir',
                      label: 'Chirurgien-Dentiste'
                    },
                    {
                      value: 'pediPodo',
                      label: 'Pédicure-podologue'
                    },
                    {
                      value: 'other',
                      label: 'Autre',
                      precision: true
                    }
                  ]
                }
              },
              {
                value: 'service',
                label: 'Un service de régulation / urgence',
                sublevel: {
                  modelname: 'subLevelII',
                  type: 'checkbox',
                  options: [
                    {
                      value: 'samu',
                      label: 'SAMU / Pompiers'
                    },
                    {
                      value: 'emergency',
                      label: 'Service des urgences'
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          value: false,
          label: 'Non'
        }
      ]
    };
  }
};
</script>
