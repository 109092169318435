<template>
  <div>
    <p v-if="title != null" :class="getClassP(level)">{{ title }}</p>

    <div v-for="elem in options" :key="elem.value" class="ma-0">
      <v-checkbox
        v-model="model.content"
        :value="elem.value"
        :class="getClass(level) + ' mb-0'"
        :rules="rules"
        @change="clearOrphean($event)"
      >
        <template v-slot:label>
          <div v-html="elem.label"></div>
        </template>
      </v-checkbox>

      <!-- sublevel -->
      <div
        v-if="
          elem.hasOwnProperty('sublevel') && elem.sublevel.type == 'checkbox'
        "
      >
        <v-expand-transition>
          <div v-show="model.content.includes(elem.value)">
            <checkboxesForm
              :title="elem.sublevel.title || null"
              :options="
                elem.sublevel.options || [{ value: '', label: 'crash' }]
              "
              :model="model[elem.sublevel.modelname]"
              :level="level + 1"
              :isRequired="model.content.includes(elem.value)"
            ></checkboxesForm>
          </div>
        </v-expand-transition>
      </div>

      <div
        v-if="elem.hasOwnProperty('sublevel') && elem.sublevel.type == 'radio'"
      >
        <v-expand-transition>
          <div v-show="model.content.includes(elem.value)">
            <radioForm
              :title="elem.sublevel.title || null"
              :options="elem.sublevel.options"
              :model="model[elem.sublevel.modelname]"
              :level="level + 1"
              :isRequired="model.content.includes(elem.value)"
            ></radioForm>
          </div>
        </v-expand-transition>
      </div>

      <!-- precision -->
      <v-expand-transition>
        <v-text-field
          v-model="model[elem.precname || 'precision']"
          v-if="elem.precision"
          v-show="model.content.includes(elem.value)"
          label="Précisez"
          outlined
          dense
          :class="getClass(level + 1)"
          :rules="inputRules(elem.value)"
        ></v-text-field>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
/**
 * This component allows to create a checkbox form
 * from a list and bind it to the v-model given in
 * content.
 *
 * It allows to specifiy a sublevel, list or check-
 * boxes, and to add a precision field.
 *
 * Format du JSON en entrée:
 *  - sublevel: {
 *        modelname: 'sublevel',
 *        type: 'checkbox/radio',
 *        options: Array}
 *  - precision: true
 */
export default {
  name: 'checkboxesForm',

  props: {
    title: { type: String, required: false },
    options: { type: Array, required: true },
    model: { type: Object, required: true },
    level: { type: Number, required: true },
    isRequired: { type: Boolean, default: true }
  },

  data: function() {
    return {}
  },

  computed: {
    rules() {
      return this.isRequired
        ? [v => v.length > 0 || 'Veuillez sélectionner au moins une valeur']
        : [() => true]
    }
  },

  components: {
    radioForm: () => import('./radioForm')
  },

  methods: {
    getClass(level) {
      return 'ml-' + level * 4 + ' pl-' + level * 4 + ' mb-0'
    },
    getClassP(level) {
      return (
        'ml-' +
        level * 4 +
        ' pl- ' +
        level * 4 +
        ' font-weight-medium mt-4 mb-1'
      )
    },
    inputRules(val) {
      return this.model.content.includes(val)
        ? [v => !!v || 'Champ requis']
        : []
    },
    clearOrphean(_selection) {
      // parcourir le niveau actuel des options
      // quand l'option n'appartient pas à la selection actuelle
      // alors on clean le sous-niveau et la precision
      // console.log(_selection)
      // console.log(this.model)
      // console.log(this.options)

      this.options.forEach(element => {
        if (!_selection.includes(element.value)) {
          if (element.hasOwnProperty('sublevel')) {
            // console.log('Edit this.'+element.sublevel.modelname +'.content as '+element.sublevel.type)
            this.model[element.sublevel.modelname].content =
              'checkbox' == element.sublevel.type ? [] : ''
          }
          if (element.precision) {
            this.model[element.precname || 'precision'] = ''
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.v-input--checkbox {
  margin: 0px;
}

/deep/ .v-messages {
  min-height: 0 !important;
}
</style>
