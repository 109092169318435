<template>
  <div>
    <component
      v-for="(item, i) in items"
      :key="i"
      :is="item"
      class="index"
    ></component>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      appName: process.env.VUE_APP_TITLE || 'Officines'
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    items() {
      if (!this.currentUser) {
        return [
          () => import('../components/HeadingSection'),
          () => import('../components/CitationSection'),
          () => import('../components/LogosSection'),
          () => import('../components/WebinarVideoSection'),
          () => import('../components/VideoPresentationSection')
        ]
      } else {
        return [
          () => import('../components/HeadingSection'),
          () => import('../components/BannerSection'),
          () => import('../components/CalendarSection'),
          () => import('../components/UrpsSection'),
          () => import('../components/WebinarVideoSection'),
          () => import('../components/VideoPresentationSection')
        ]
      }
    }
  },
  components: {
    btn: () => import('../../../core/BasicBtn.vue'),
    'page-heading': () => import('../../../core/PageHeading.vue'),
    'page-title': () => import('../../../core/PageTitle.vue'),
    'section-title': () => import('../../../core/Title.vue'),
    person: () => import('../../../core/Person.vue'),
    quote: () => import('../../../core/Citation.vue')
  }
}
</script>

<style scoped>
.index {
  /*position: relative;*/
}
</style>
