<template>
  <div>
    <v-sheet color="secondary lighten-1 py-16 mb-0" dark></v-sheet>
    <v-container class="mt-n16 pt-0 d-flex flex-column align-center">
      <v-avatar color="primary lighten-3" size="120" class="mb-4 elevation-5">
        <img src="@/assets/img/avatar/profile-pic.svg" />
      </v-avatar>
      <h6 class="text-h6">Mon Officine</h6>
      <div class="d-flex flex-column">
        <div class="d-inline-flex align-center">
          <v-icon class="pr-4" left>mdi-card-account-details-outline</v-icon>
          <p
            class="text-subtitle-2 font-weight-regular grey--text text--darken-2 my-1"
          >
            {{ user.finess }}
          </p>
        </div>
        <div class="d-inline-flex">
          <v-icon class="pr-4" left>mdi-email</v-icon>
          <p
            class="text-subtitle-2 font-weight-regular grey--text text--darken-2 my-1"
          >
            {{ user.email }}
          </p>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import User from '../../../models/user'

export default {
  name: 'Logo',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  mounted() {}
}
</script>
