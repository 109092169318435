import AuthService from '../services/auth.service'
import UserService from '../services/user.service'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        response => {
          commit('loginSuccess', response.user)
          return Promise.resolve(response.user)
        },
        error => {
          commit('loginFailure', error)
          return Promise.reject(error.response.data, { root: true })
        }
      )
    },
    logout({ commit }, user) {
      return AuthService.logout(user).then(
        response => {
          commit('logout')
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error.response.data)
        }
      )
    },
    update({ commit }, user) {
      return UserService.putUser(user, user._id).then(
        response => {
          commit('updateSucess', response.data)
          return Promise.resolve(response.data)
        },
        error => {
          commit('updateFailure', error)
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
    updateSucess(state, payload) {
      state.user = payload.user
      localStorage.setItem('user', JSON.stringify(payload.user))
    }
  }
}
