<template>
  <div>
    <nav-bar></nav-bar>
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'Default',

  components: {
    'nav-bar': () => import('../core/SideNavBar'),
    'footer-bar': () => import('../core/FooterBar')
  }
}
</script>
