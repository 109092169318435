<template>
  <div>
    <checkboxForm
      title="Quelle orientation avez-vous recommandée au patient ? "
      :options="recommendedOrientationOptions"
      :model="recommendedOrientation"
      :level="0"
    ></checkboxForm>
  </div>
</template>

<script>
export default {
  name: 'recommendedOrientationForm',
  props: {
    recommendedOrientation: { type: Object, required: true }
  },
  components: {
    checkboxForm: () => import('../../../core/formFactory/checkboxesForm')
  },
  data: function() {
    return {
      recommendedOrientationOptions: [
        {
          value: 'returnHome',
          label: 'Retour au domicile / chez un proche',
          sublevel: {
            modelname: 'subLevelA',
            type: 'radio',
            // SubLevel returnhome Options
            options: [
              {
                value: 'withoutFollowUp',
                label: 'Retour au domicile sans suivi spécifique'
              },
              {
                value: 'withFollowUp',
                label: 'Retour au domicile avec un suivi de la pharmacie',
                sublevel: {
                  modelname: 'subLevel',
                  type: 'checkbox',
                  // SubLevel follow_up Options
                  options: [
                    {
                      value: 'sms',
                      label: 'SMS'
                    },
                    {
                      value: 'phone',
                      label: 'Téléphone'
                    },
                    {
                      value: 'mail',
                      label: 'Email '
                    },
                    {
                      value: 'anotherVisit',
                      label: 'Nouvelle visite à l’officine'
                    },
                    {
                      value: 'other',
                      label: 'Autre'
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          value: 'heathprofessionnal',
          label: 'Un professionnel de santé',
          sublevel: {
            modelname: 'subLevelB',
            type: 'radio',
            // Sublevel Professionnel de santé Options
            options: [
              {
                value: 'usual',
                label: 'Habituel du patient',
                sublevel: {
                  modelname: 'subLevel',
                  type: 'radio',
                  // Sublevel Habituel Options
                  options: [
                    {
                      value: 'generalPractitioner',
                      label: 'Médecin Traitant',
                      sublevel: {
                        modelname: 'subLevel',
                        type: 'radio',
                        // Sublevel Médecin Traitant Options
                        options: [
                          {
                            value: 'teleConsultingSide',
                            label: 'Téléconsultation au sein de l’officine'
                          },
                          {
                            value: 'officineTeamRdv',
                            label: 'Prise de RDV <u>par l’équipe officinale</u>'
                          },
                          {
                            value: 'patientRdv',
                            label:
                              'Prise de RDV/Téléconsultation <u>par le patient</u>'
                          }
                        ]
                      }
                    },
                    {
                      value: 'specialisedDoctor',
                      label: 'Médecin spécialiste',
                      precision: true
                    },
                    {
                      value: 'nurse',
                      label: 'Infirmier'
                    },
                    {
                      value: 'kine',
                      label: 'Kinésithérapeute'
                    },
                    {
                      value: 'biologicalLaboratory',
                      label: 'Laboratoire de Biologie '
                    },
                    {
                      value: 'midwife',
                      label: 'Sage-femme'
                    },
                    {
                      value: 'dentalChir',
                      label: 'Chirurgien-Dentiste'
                    },
                    {
                      value: 'pediPodo',
                      label: 'Pédicure-podologue'
                    },
                    {
                      value: 'other',
                      label: 'Autre',
                      precision: true
                    }
                  ]
                }
              },
              {
                value: 'nonUsual',
                label: 'Non-habituel du patient',
                sublevel: {
                  modelname: 'subLevel',
                  type: 'radio',
                  // Sublevel Non-Habituel Options
                  options: [
                    {
                      value: 'generalDoctor',
                      label: 'Médecin généraliste',
                      sublevel: {
                        modelname: 'subLevel',
                        type: 'radio',
                        // Sublevel Médecin Généraliste Options
                        options: [
                          {
                            value: 'teleconsultingSide',
                            label: 'Téléconsultation au sein de l’officine'
                          },
                          {
                            value: 'officineTeamRdv',
                            label: 'Prise de RDV <u>par l’équipe officinale</u>'
                          },
                          {
                            value: 'patientRdv',
                            label:
                              'Prise de RDV/Téléconsultation <u>par le patient</u>'
                          }
                        ]
                      }
                    },
                    {
                      value: 'specialiseddoctor',
                      label: 'Médecin spécialiste',
                      sublevel: {
                        modelname: 'subLevel',
                        type: 'radio',
                        // Sublevel Médecin Spécialiste Options
                        options: [
                          {
                            value: 'teleconsultingSidespe',
                            label: 'Téléconsultation au sein de l’officine'
                          },
                          {
                            value: 'officineTeamRdvSpe',
                            label: 'Prise de RDV <u>par l’équipe officinale</u>'
                          },
                          {
                            value: 'patientRdvSpe',
                            label:
                              'Prise de RDV/Téléconsultation <u>par le patient</u>'
                          }
                        ]
                      }
                    },
                    {
                      value: 'biologicalLab',
                      label: 'Laboratoire de biologie'
                    },
                    {
                      value: 'otherPro',
                      label: 'Autre professionnel de santé',
                      precision: true
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          value: 'antiPoisonCenter',
          label: 'Centre Antipoison'
        },
        {
          value: 'emergencies',
          label: 'Un service d’urgence/régulation/appel d’urgence',
          sublevel: {
            modelname: 'subLevelD',
            type: 'checkbox',
            // Sublevel Service d'urgence Options
            options: [
              {
                value: 'pharmacistCall',
                label: 'Appel du pharmacien (SAMU / Pompiers)'
              },
              {
                value: 'emergencyOrientation',
                label: 'Orientation du patient vers un service d’urgences'
              }
            ]
          }
        }
      ]
    }
  }
}
</script>
