<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <!-- Age -->
        <p class="font-weight-medium mt-4 mb-1">Âge du patient</p>

        <v-radio-group class="mt-1" v-model="answers.patientAge" :rules="rulesReq">
          <v-radio
            v-for="elem in ages"
            :key="elem.value"
            :label="elem.label"
            :value="elem.value"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" class="py-0">
        <!-- Pays -->
        <p class="font-weight-medium mt-4 mb-1">Pays de résidence</p>

        <v-radio-group class="mt-1" v-model="answers.country.content" :rules="rulesReq">
          <v-radio
            v-for="elem in countries"
            :key="elem.value"
            :label="elem.label"
            :value="elem.value"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" class="py-0">
        <!-- Sexe -->
        <p class="font-weight-medium mt-4 mb-1">Sexe du patient</p>
        <v-radio-group class="mt-1" v-model="answers.sex" :rules="rulesReq">
          <v-radio label="Femme" value="femme"></v-radio>
          <v-radio label="Homme" value="homme"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" class="py-0">
        <!-- C2S -->
        <p class="font-weight-medium mt-4 mb-1">
          Le patient est-il bénéficiaire de la C2S (Complémentaire santé
          solidaire) ?
        </p>
        <v-radio-group class="mt-1" v-model="answers.c2s" :rules="rulesReq">
          <v-radio label="Oui" value="true"></v-radio>
          <v-radio label="Non" value="false"></v-radio>
          <v-radio label="Ne sait pas" value="unknown"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" class="py-0">
        <!-- C2S -->
        <p class="font-weight-medium mt-4 mb-1">
          Le patient est-il traité pour une ALD (Affection de Longue Durée) ?
        </p>
        <v-radio-group class="mt-1" v-model="answers.ald" :rules="rulesReq">
          <v-radio label="Oui" value="true"></v-radio>
          <v-radio label="Non" value="false"></v-radio>
          <v-radio label="Ne sait pas" value="unknown"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" class="py-0">
        <!-- Médecin traitant -->
        <p class="font-weight-medium mt-4 mb-1">Le patient a-t-il un médecin traitant ?</p>
        <v-radio-group class="mt-1" v-model="answers.patientDoctor" :rules="rulesReq">
          <v-radio label="Oui" value="true"></v-radio>
          <v-radio label="Non" value="false"></v-radio>
          <v-radio label="Ne sait pas" value="unknown"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" class="py-0">
        <!-- Connu -->
        <p class="font-weight-medium mt-4 mb-1">
          Le patient est-il connu de l’officine ?
        </p>
        <v-radio-group class="mt-1" v-model="answers.knownPatient" :rules="rulesReq">
          <v-radio
            label="Oui, c’est un patient habituel"
            value="regularcustomer"
          ></v-radio>
          <v-radio
            label="Oui, c’est un patient occasionnel"
            value="occasionalcustomer"
          ></v-radio>
          <v-radio label="Non" value="nope"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'demographicDataForm',
  props: {
    answers: { type: Object, required: true }
  },
  data: function() {
    return {
      ages: [
        { value: 'infant', label: 'Nourisson (<2ans)' },
        { value: 'child', label: 'Enfants (<16ans)' },
        { value: 'young', label: 'Jeunes Adultes (16 ans > < 40 ans)' },
        { value: 'adult', label: 'Adultes (40 ans > < 65 ans)' },
        { value: 'senior', label: 'Seniors (>65 ans)' }
      ],
      countries: [
        { value: 'french', label: 'France' },
        {
          value: 'borderer',
          label: 'Pays frontaliers (Belgique, Luxembourg, Allemagne, Suisse)'
        },
        { value: 'other', label: 'Autre' },
        { value: 'unknown', label: 'Ne sait pas' }
      ],
      rulesReq: [v => !!v || 'Veuillez sélectionner une valeur']
    }
  }
}
</script>
