<template>
  <div>
    <checkboxesForm
      title="Avez-vous recueilli des informations spécifiques concernant l’état de santé du patient ?"
      :options="patientHealthStatusOptions"
      :model="patientHealthStatus"
      :level="0"
    ></checkboxesForm>
  </div>
</template>

<script>
import checkboxesForm from '../../../core/formFactory/checkboxesForm';

export default {
  name: 'patientHealthStatusForm',
  props: {
    patientHealthStatus: { type: Object, required: true }
  },
  components: {
    checkboxesForm
  },
  data: function() {
    return {
      patientHealthStatusOptions: [
        {
          value: 'fromPatient',
          label: 'Directement auprès du patient',
          sublevel: {
            modelname: 'subLevelA',
            type: 'checkbox',
            // SubLevel A Options
            options: [
              {
                value: 'familialAntecedents',
                label: 'Antécédents familiaux'
              },
              {
                value: 'personnalAntecedents',
                label: 'Antécédents personnels / Voyages récents / Allergies'
              },
              {
                value: 'mainFactors',
                label:
                  'Principaux facteurs de risque (par exemple cardiovasculaires…) '
              },
              {
                value: 'historicalTreatment',
                label:
                  'Historique des traitements médicamenteux pour la plainte'
              },
              {
                value: 'medicinesTreatment',
                label: 'Traitements médicamenteux actuels/ en cours'
              },
              {
                value: 'histoComplaint',
                label:
                  'Historique de la plainte aigüe (motif de la visite à l’officine)'
              },
              {
                value: 'covidDiag',
                label: 'Diagnostic antérieur d’infection COVID-19'
              },
              {
                value: 'pregnancy',
                label: 'Grossesse en cours'
              }
            ]
          }
        },
        {
          value: 'fromNear',
          label: 'Auprès d’un membre de la famille / proche / aidant',
          sublevel: {
            modelname: 'subLevelB',
            type: 'checkbox',
            // SubLevel B Options
            options: [
              {
                value: 'familialAntecedents',
                label: 'Antécédents familiaux'
              },
              {
                value: 'personalAntecedents',
                label: 'Antécédents personnels / Voyages récents / Allergies'
              },
              {
                value: 'mainFactors',
                label:
                  'Principaux facteurs de risque (par exemple cardiovasculaires…) '
              },
              {
                value: 'historyTreatment',
                label: 'Historique des traitements médicamenteux de la plainte'
              },
              {
                value: 'medicines',
                label: 'Traitements médicamenteux actuels/ en cours'
              },
              {
                value: 'historyComplaint',
                label:
                  'Historique de la plainte aigüe (motif de la visite à l’officine)'
              },
              {
                value: 'covidDiag',
                label: 'Diagnostic antérieur d’infection COVID-19'
              },
              {
                value: 'pregnancy',
                label: 'Grossesse en cours'
              }
            ]
          }
        },
        {
          value: 'dpConsult',
          label: 'Consultation du Dossier Pharmaceutique (DP)'
        },
        {
          value: 'dmpConsult',
          label: 'Consultation du Dossier Médical Partagé (DMP)'
        },
        {
          value: 'no',
          label: 'Non',
          sublevel: {
            modelname: 'subLevelE',
            type: 'checkbox',
            // SubLevel E Options
            options: [
              {
                value: 'impossible',
                label: 'Patient non en état de répondre aux questions'
              },
              {
                value: 'unwanted',
                label: 'Patient ne souhaitant pas répondre aux questions'
              },
              {
                value: 'timeLake',
                label: 'Manque de temps'
              }
            ]
          }
        },
        {
          value: 'otherPhs',
          label: 'Autres',
          precision: true
        }
      ]
    };
  }
};

/*
sublevel: {
         modelname: 'sublevel',
         type: 'checkbox/radio',
         options: Array
         }
*/
</script>
