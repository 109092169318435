import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL_API + 'api/patientinfo/'

class FormulaireService {
  postFormulaire(formulaire) {
    return axios
      .post(API_URL, {
        formulaire: formulaire,
        // email: "officine@yopmail.fr" , //TODO
        // subject: '<Trod Officine> - Formulaire de test COVID',
        // template: 'd-37f84286631045748220d1fd9ec96509',
        headers: authHeader()
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  }

  putFormulaire(formulaire, id) {
    return axios
      .put(API_URL + id, {
        formulaire: formulaire,
        headers: authHeader()
      })
      .catch(err => {
        if (err.response) {
          return err.response
        } else {
          return err
        }
      })
  }

  countPatientsInfo() {
    return axios.get(API_URL + 'count-patients', { headers: authHeader() })
  }

  countPatientsInfoByFiness() {
    return axios.get(API_URL + 'count-patients-by-finess', {
      headers: authHeader()
    })
  }

  countSessionByFiness() {
    return axios.get(API_URL + 'count-session-by-finess', {
      headers: authHeader()
    })
  }

  getSessionDates() {
    return axios.get(API_URL + 'dates', { headers: authHeader() })
  }

  currentUser() {
    return this.$store.state.auth.user
  }

  deleteFormulaire(id) {
    return axios.delete(API_URL + id, {
      headers: authHeader()
    })
  }

  getAllFormulaire() {
    return axios.get(API_URL, { headers: authHeader() })
  }

  getFinessFormulaire() {
    return axios.get(API_URL + 'all', { headers: authHeader() })
  }

  getFormulaireById(id) {
    return axios.get(API_URL + 'id/' + id, { headers: authHeader() })
  }

  getExport() {
    return axios.get(API_URL + 'export', {
      headers: authHeader(),
      responseType: 'blob'
    })
  }

  getFormulaireCountByDate() {
    return axios.get(API_URL + 'creation/count', {
      headers: authHeader()
    })
  }

  getCovidCount() {
    return axios.get(API_URL + 'covid-count', {
      headers: authHeader()
    })
  }
}

export default new FormulaireService()
