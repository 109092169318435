<template>
  <div>
    <p class="font-weight-medium">
      Quels actes avez-vous réalisés ?
    </p>
    <p class="font-italic">
      Rappel : Confidentialité des données / Absence de lien entre réponses au
      questionnaire et données administratives
    </p>

    <radioForm
      title="Réalisé un acte technique"
      :options="technicalOptions"
      :model="performedActs.technical"
      :level="1"
    ></radioForm>

    <radioForm
      title="Dispensé un produit"
      :options="productDispensedOptions"
      :model="performedActs.productDispensed"
      :level="1"
    ></radioForm>

    <radioForm
      title="Délivré un conseil / explication"
      :options="adviceProvidedOptions"
      :model="performedActs.adviceProvided"
      :level="1"
    ></radioForm>

    <radioForm
      title="Réalisé un autre acte"
      :options="otherActOptions"
      :model="performedActs.otherAct"
      :level="1"
    ></radioForm>
  </div>
</template>

<script>
import radioForm from '../../../core/formFactory/radioForm'

export default {
  name: 'performedActsForm',
  props: {
    performedActs: { type: Object, required: true }
  },
  components: {
    radioForm
  },
  data: function() {
    return {
      technicalOptions: [
        {
          value: true,
          label: 'Oui',
          sublevel: {
            modelname: 'subLevel',
            type: 'checkbox',
            // SubLevel Options "Oui"
            options: [
              {
                value: 'emergencyActions',
                label: 'Application de gestes & soins d’urgence',
                sublevel: {
                  modelname: 'subLevelI',
                  type: 'checkbox',
                  // SubLevel i 0ptions
                  options: [
                    {
                      value: 'pmo',
                      label:
                        'Administration/Application à l’officine d’un médicament de PMO (Prescription Médicale Obligatoire)'
                    },
                    {
                      value: 'defibrillation',
                      label: 'Défibrillation'
                    },
                    {
                      value: 'cardiacMassage',
                      label: 'Massage cardiaque'
                    },
                    {
                      value: 'pls',
                      label: 'Position latérale de sécurité'
                    },
                    {
                      value: 'legs',
                      label: 'Surélévation des jambes'
                    },
                    {
                      value: 'other',
                      label: 'Autres',
                      precision: true
                    }
                  ]
                }
              },
              {
                value: 'trodMeasurements',
                label: 'Prises de mesures et TRODs',
                sublevel: {
                  modelname: 'subLevelII',
                  type: 'checkbox',
                  // Sublevel ii Options
                  options: [
                    {
                      value: 'arterialMeasurement',
                      label: 'Mesure de la tension artérielle'
                    },
                    {
                      value: 'temperatureMeasurment',
                      label: 'Prise de température'
                    },
                    {
                      value: 'glycemicTrod',
                      label: 'Utilisation du TROD Glycémie'
                    },
                    {
                      value: 'angineTrod',
                      label: 'Utilisation du TROD Angine (Strepto Test A)'
                    },
                    {
                      value: 'covidTrod',
                      label: 'Utilisation du TROD sérologique COVID-19'
                    },
                    {
                      value: 'covidGenic',
                      label: 'Utilisation du test COVID-19 antigénique'
                    },
                    {
                      value: 'other',
                      label: 'Autres',
                      precision: true
                    }
                  ]
                }
              },
              {
                value: 'brunClean',
                label: 'Nettoyage & désinfection de plaie ou brûlure',
                sublevel: {
                  modelname: 'subLevelIII',
                  type: 'checkbox',
                  // Sublevel iii Options
                  options: [
                    {
                      value: 'evaluation',
                      label: 'Evaluation de la gravité de la brûlure',
                      sublevel: {
                        modelname: 'burnGravity',
                        type: 'radio',
                        // Sublevel burnGravity Options
                        options: [
                          {
                            value: 'extanded',
                            label: 'Etendue'
                          },
                          {
                            value: 'notExtanded',
                            label: 'Non étendue'
                          }
                        ]
                      }
                    },
                    {
                      value: 'garrot',
                      label: 'Pose d’un garrot'
                    },
                    {
                      value: 'compressBandage',
                      label: 'Pose d’un pansement compressif'
                    },
                    {
                      value: 'adhesiveStructure',
                      label: 'Pose de sutures adhésives'
                    },
                    {
                      value: 'technicalBandage',
                      label: 'Réalisation d’un pansement technique'
                    },
                    {
                      value: 'simpleBandage',
                      label: 'Réalisation d’un pansement simple'
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          value: false,
          label: 'Non'
        }
      ],

      productDispensedOptions: [
        {
          value: true,
          label: 'Oui',
          sublevel: {
            modelname: 'subLevel',
            type: 'checkbox',
            // SubLevel "Oui" Options
            options: [
              {
                value: 'autotest',
                label: 'Autotests'
              },
              {
                value: 'aroma',
                label: 'Aromathérapie'
              },
              {
                value: 'medicalDevice',
                label: 'Dispositifs Médicaux'
              },
              {
                value: 'homeo',
                label: 'Homéopathie'
              },
              {
                value: 'medications',
                label: 'Médicaments'
              },
              {
                value: 'phyto',
                label: 'Phytothérapie'
              },
              {
                value: 'other',
                label: 'Autres'
              }
            ]
          }
        },
        {
          value: false,
          label: 'Non'
        }
      ],

      adviceProvidedOptions: [
        {
          value: true,
          label: 'Oui',
          sublevel: {
            modelname: 'subLevel',
            type: 'checkbox',
            // SubLevel advice_yes Options
            options: [
              {
                value: 'ontreatment',
                label: 'Sur un traitement',
                sublevel: {
                  modelname: 'subLevel',
                  type: 'radio',
                  // Sublevel on_treatment Options
                  options: [
                    {
                      value: 'aigu',
                      label: 'Aigu'
                    },
                    {
                      value: 'chronique',
                      label: 'Chronique'
                    }
                  ]
                }
              },
              {
                value: 'symptomeOrPatho',
                label: 'Sur un symptôme ou une pathologie'
              },
              {
                value: 'hygienoDiet',
                label: 'Conseils hygiéno-diététiques '
              },
              {
                value: 'other',
                label: 'Autre',
                precision: true
              }
            ]
          }
        },
        {
          value: false,
          label: 'Non'
        }
      ],

      otherActOptions: [
        {
          value: true,
          label: 'Oui',
          precision: true
        },
        {
          value: false,
          label: 'Non'
        }
      ]
    }
  }
}

/*

sublevel: {
         modelname: 'subLevel',
         type: 'checkbox/radio',
         // Sublevel __ Options
         options: Array
         }
*/
</script>
