<template>
  <div>
    <radioForm
      title="Quelle relation entretenez-vous avec le professionnel de santé vers qui vous avez orienté votre patient ?"
      :options="healthProfessionalRelationOptions"
      :model="healthProfessionalRelation"
      :level="0"
      v-if="display"
    ></radioForm>
  </div>
</template>

<script>
export default {
  name: 'healthProfessionalRelationForm',
  props: {
    healthProfessionalRelation: { type: Object, required: true },
    display: { type: Boolean, required: true }
  },
  components: {
    radioForm: () => import('../../../core/formFactory/radioForm')
  },
  data: function() {
    return {
      healthProfessionalRelationOptions: [
        {
          value: 'pluriPro',
          label:
            'Il appartient à mon réseau pluri professionnel (MSP, CPTS, ESP, …)'
        },
        {
          value: 'proximity',
          label: 'Il se trouve à proximité de mon officine'
        },
        {
          value: 'unknown',
          label: 'Je ne le connais pas'
        },
        {
          value: 'notOrienting',
          label: 'Je n’ai pas orienté vers un professionnel de santé spécifique'
        }
      ]
    }
  }
}
</script>
