<template>
  <section class="grey lighten-4">
    <v-container>
      <!-- <v-divider inset></v-divider> -->
      <v-subheader class="text-center text-h6 font-weight-light pl-0">
        <v-icon>mdi-rhombus-medium-outline</v-icon> Description de l'officine
      </v-subheader>
      <v-row>
        <!-- TAILLE DE L'EQUIPE -->
        <v-col>
          <v-list-item class="pl-0">
            <v-list-item-content class="mb-0 pb-0">
              <v-list-item-title class="overline font-weight-bold">
                Taille de l'équipe officinale
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-row justify="space-between">
            <div v-for="(number, index) in numbers" :key="index">
              <v-col class="d-flex flex-column mb-0 pl-0 num-col">
                <v-avatar
                  class="mt-0 align-self-center"
                  color="primary"
                  large
                  left
                >
                  <span class="white--text headline font-weight-black">{{
                    getComputed(number.value)
                  }}</span>
                </v-avatar>
                <p class="py-0 align-self-center">
                  {{ number.label }}
                </p>
              </v-col>
            </div>
          </v-row>
        </v-col>

        <!-- SERVICES PROPOSES -->
        <v-col offset-md="2">
          <v-list-item class="pl-0">
            <v-list-item-content class="pb-0">
              <v-list-item-title class="overline font-weight-bold mb-0">
                Services proposés
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-chip
            v-for="pill in getFilteredPills"
            :key="pill.label"
            class="ma-2"
            color="grey darken-1"
            pill
            dark
          >
            <v-icon left>
              {{ pill.icon }}
            </v-icon>
            {{ pill.label }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row>
        <!-- TYPE D'OFFICINE -->
        <v-col>
          <v-list-item class="pl-0">
            <v-list-item-content class="mb-0 pb-0">
              <v-list-item-title class="overline font-weight-bold">
                Type d'officine
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div>{{ officineType }}</div>
        </v-col>

        <!-- LCOAL DE CONFIDENTIALITE -->
        <v-col offset-md="2">
          <v-list-item class="pl-0">
            <v-list-item-content class="pb-0">
              <v-list-item-title class="overline font-weight-bold mb-0">
                Local de confidentialité
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div>{{ privacyLocal }}</div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Informations',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      numbers: [
        {
          value: 'licensedPharmacists',
          label: 'Titulaires'
        },
        {
          value: 'adjointsPharmacists',
          label: 'Adjoints'
        },
        {
          value: 'preparator',
          label: 'Préparateurs'
        },
        {
          value: 'student',
          label: 'Etudiants'
        }
      ],
      pills: [
        {
          icon: 'mdi-account-search',
          label: 'Conseils',
          value: 'conseils'
        },
        {
          icon: 'mdi-check',
          label: 'Dispensation adaptée',
          value: 'adaptedDispensation'
        },
        {
          icon: 'mdi-briefcase-check',
          label: 'Entretiens pharmaceutiques',
          value: 'pharmaceuticalInterviews'
        },
        {
          icon: 'mdi-auto-fix',
          label: 'TROD',
          value: 'trodUtilisation'
        },
        {
          icon: 'mdi-thermometer',
          label: 'Prise de constante',
          value: 'constantsCheckboxes'
        },
        {
          icon: 'mdi-school',
          label: 'Education thérapeutique',
          value: 'therapeuticParticipation'
        },
        {
          icon: 'mdi-monitor',
          label: 'Téléconsultations',
          value: 'teleconsultation'
        }
      ]
    }
  },
  computed: {
    licensedPharmacists() {
      return this.user.teamSize.licensedPharmacists
    },
    adjointsPharmacists() {
      return this.user.teamSize.adjointsPharmacists
    },
    preparator() {
      return this.user.teamSize.preparator
    },
    student() {
      return this.user.teamSize.student
    },
    formatCreatedAt() {
      return new Date(this.user.createdAt).toLocaleDateString('fr-FR', {
        month: 'long',
        year: 'numeric',
        day: 'numeric'
      })
    },
    getFilteredPills() {
      return this.pills.filter(pill =>
        this.user.proposedService.content.includes(pill.value)
      )
    },
    privacyLocal() {
      return this.user.privacyLocal.content == 'true'
        ? {
            forHealth: 'Local de confidentialité dédié aux entretiens / soins',
            forOrthopedics:
              'Local de confidentialité qui est également le local d’orthopédie'
          }[this.user.privacyLocal.subLevel.content[0]]
        : 'Pas de local'
    },
    officineType() {
      return this.getOfficineType()
    }
  },
  methods: {
    getComputed(attr) {
      return this[attr]
    },
    getOfficineType() {
      switch (this.user.type.content) {
        case 'district':
          return 'Quartier'

        case 'town':
          return 'Bourg'

        case 'cityCenter':
          return 'Centre-Ville'

        case 'commercialCenter':
          return 'Centre commercial / Gare'

        case 'autre':
          return this.user.type.precision

        default:
          return "Aucun type d'officine renseigné"
      }
    }
  },
  mounted() {
    if (!this.$store.state.auth.user) {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.num-col {
  min-width: 120px;
}
</style>
