<template>
  <div>
    <checkboxForm
      title="Qui a réalisé la prise en charge au sein de l’officine ?"
      :options="whoTakeCareOptions"
      :model="whoTakeCare"
      :level="0"
    ></checkboxForm>
  </div>
</template>

<script>
export default {
  name: 'whoTakeCareForm',
  props: {
    whoTakeCare: { type: Object, required: true }
  },
   components: {
    checkboxForm: () => import('../../../core/formFactory/checkboxesForm')
  },
  data: function() {
    return {
      whoTakeCareOptions: [
         {
          value: 'pharmacist',
          label: 'Pharmacien'
        },
        {
          value: 'preparator',
          label: 'Préparateur'
        },
        {
          value: 'otherPec',
          label: 'Autre',
          precision: true
        }
      ]
    };
  }
};
</script>
