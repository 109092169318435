<template>
  <div>
    <checkboxForm
      title="Quels outils de télémédecine ont été utilisés ?"
      :options="telemedicineToolsOptions"
      :model="telemedicineTools"
      :level="0"
      v-if="display"
    ></checkboxForm>
  </div>
</template>

<script>
export default {
  name: 'telemedicineToolsForm',
  props: {
    telemedicineTools: { type: Object, required: true },
    display: { type: Boolean, required: true }
  },
  components: {
    checkboxForm: () => import('../../../core/formFactory/checkboxesForm')
  },
  data: function() {
    return {
      telemedicineToolsOptions: [
        {
          value: 'scale',
          label: 'Balance'
        },
        {
          value: 'bandage',
          label: 'Bandelettes urinaires'
        },
        {
          value: 'camera',
          label: 'Caméra'
        },
        {
          value: 'ostoscope',
          label: 'Otoscope'
        },
        {
          value: 'oxymeter',
          label: 'Oxymètre'
        },
        {
          value: 'picture',
          label: 'Photo'
        },
        {
          value: 'stetoscope',
          label: 'Stéthoscope'
        },
        {
          value: 'tensiometer',
          label: 'Tensiomètre'
        },
        {
          value: 'thermometer',
          label: 'Thermomètre'
        },
        {
          value: 'trod',
          label: 'TRODs',
          sublevel: {
            type: 'checkbox',
            modelname: 'subLevelTrod',
            options: [
              { value: 'covid', label: 'COVID-19' },
              { value: 'glycemic', label: 'Glycémie' },
              { value: 'strepto', label: 'Strepto Test A' }
            ]
          }
        },
        {
          value: 'unknown',
          label: 'Ne sait pas'
        }
      ]
    }
  }
}
</script>
