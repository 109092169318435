import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL_API + 'api/users/'

class UserService {
  me() {
    return axios
      .get(API_URL + 'me', {
        headers: authHeader()
      })
      .then(response => {
        return response
      })
      .catch(err => {
        throw err.response.data.error
      })
  }

  getUsers() {
    return axios
      .get(API_URL, {
        headers: authHeader()
      })
      .then(response => {
        return response
      })
      .catch(err => {
        throw err.response.data.error
      })
  }

  getUsersInscriptionCountByDate() {
    return axios
      .get(API_URL + 'inscription/count', {
        headers: authHeader()
      })
      .then(response => {
        return response
      })
      .catch(err => {
        throw err.response.data.error
      })
  }
  
  getUsersCount() {
    return axios
      .get(API_URL + 'countUsers', {
        headers: authHeader()
      })
      .then(response => {
        return response
      })
      .catch(err => {
        throw err.response.data.error
      })
  }

  countPostal() {
    return axios
      .get(API_URL + 'count-postal', {
        headers: authHeader()
      })
      .then(response => {
        return response
      })
      .catch(err => {
        throw err.response.data.error
      })
  }

  putUser(user, id) {
    // console.log(id)
    return axios
      .put(API_URL + 'updateUser', {
        user: user,
        id: id,
        headers: authHeader()
      })
      .then(response => {
        return response
      })
      .catch(err => {
        throw err.response.data.error
      })
  }

  getExport() {
    return axios.get(API_URL + 'export', {
      headers: authHeader(),
      responseType: 'blob'
    })
  }

  verify(userId) {
    return axios.put(API_URL + 'verify', {
      id: userId,
      headers: authHeader(),
    }).then(response => {
      return response;
    }).catch((err) => {
      throw err.response.data.error
    });
  }
}

export default new UserService()
