<template>
  <div>
    Déconnexion
  </div>
</template>

<script>
export default {
  name: 'Logout',

  mounted() {
    const user = this.$store.state.auth.user
    this.$store
      .dispatch('auth/logout', user)
      .then(response => {
        this.$toasted.show(response.message, {
          type: 'success',
          duration: '5000',
          position: 'top-center'
        })
        this.$router.push('/')
      })
      .catch(err => {
        this.$router.go(-1)
        this.$toasted.show(err.error, {
          type: 'error',
          position: 'top-center',
          action: {
            text: 'Cancel',
            duration: '5000',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            }
          }
        })
      })
  }
}
</script>
