<template>
  <v-container>
    <v-form ref="formDescription" v-model="valid">
      <v-row>
        <v-col cols="12">
          <radio-form
            title="Situation de votre officine"
            :options="situationOptions"
            :model="user.situation"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- Type -->
        <v-col cols="12">
          <radio-form
            title="Quel est le type de votre officine ?"
            :options="typeOptions"
            :model="user.type"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- Votre officine a-t-elle une collaboration formalisée avec d’autres professionnels de santé ?  -->
        <v-col cols="12">
          <radio-form
            title="Votre officine a-t-elle une collaboration formalisée avec d’autres
            professionnels de santé ?"
            :options="collaborationOptions"
            :model="user.collaboration"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- 6.	Votre officine adhère-t-elle à un groupement de pharmaciens ? Réponse unique -->
        <v-col cols="12">
          <radio-form
            title="Votre officine adhère-t-elle à un groupement de pharmaciens ?"
            :options="groupmentOptions"
            :model="user.groupment"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- 7.	Taille de votre équipe officinale (personnes habilitées à dispenser des médicaments) -->
        <v-col cols="12" md="4">
          <p class="ml-0 pl- 0 font-weight-medium mt-4 mb-1">
            Taille de votre équipe officinale (personnes habilitées à dispenser
            des médicaments)
          </p>
          <v-text-field
            v-model="user.teamSize.licensedPharmacists"
            label="Pharmaciens titulaires"
            type="number"
            dense
            outlined
            :rules="[v => v >= 0]"
          ></v-text-field>
          <v-text-field
            v-model="user.teamSize.adjointsPharmacists"
            label="Pharmaciens adjoints "
            type="number"
            dense
            outlined
            :rules="[v => v >= 0]"
          ></v-text-field>
          <v-text-field
            v-model="user.teamSize.preparator"
            label="Préparateurs"
            type="number"
            dense
            outlined
            :rules="[v => v >= 0]"
          ></v-text-field>
          <v-text-field
            v-model="user.teamSize.student"
            label="Etudiants en pharmacie"
            type="number"
            dense
            outlined
            :rules="[v => v >= 0]"
          ></v-text-field>
        </v-col>

        <!-- 8.	Disposez-vous d’un local de confidentialité ? Réponse unique -->
        <v-col cols="12">
          <radio-form
            title="Disposez-vous d’un local de confidentialité ?"
            :options="privacyLocalOptions"
            :model="user.privacyLocal"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- 9.	Quels sont les services proposés & réalisés au sein de votre officine à l’heure actuelle ?  -->

        <v-col cols="12">
          <checkbox-form
            title="Quels sont les services proposés & réalisés au sein de votre
            officine à l’heure actuelle ?"
            :options="proposedServiceOptions"
            :model="user.proposedService"
            :level="0"
          ></checkbox-form>
        </v-col>

        <!-- 10.	A quelle fréquence estimez-vous recevoir des demandes de premier recours non programmées ?  -->
        <v-col cols="12" md="6">
          <radio-form
            title=" A quelle fréquence estimez-vous recevoir des demandes de premier
            recours non programmées ?"
            :options="frequencyOptions"
            :model="user.frequency"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- 11.	Depuis la crise de la COVID, avez-vous l’impression que la crise a eu un impact sur les demandes de 1er recours non programmées ? -->
        <v-col cols="12">
          <checkbox-form
            title="Depuis la crise de la COVID-19, avez-vous l’impression que la crise a
            eu un impact sur les demandes de 1er recours non programmées ?"
            :options="crisisImpactOptions"
            :model="user.crisisImpact"
            :level="0"
          ></checkbox-form>
        </v-col>
      </v-row>
      <v-btn color="primary" @click="isValidForm">
        Etape suivante
      </v-btn>
      <v-btn color="primary" @click="goBack" text v-if="!editionMode">
       Etape précédente
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: ['user', 'active', 'editionMode'],

  data() {
    return {
      valid: false,
      activeProps: 0,

      situationOptions: [
        {
          value: 'rural',
          label: 'Rural'
        },
        {
          value: 'less10k',
          label: 'Moins de 10 000 hab.'
        },
        {
          value: '10kTo20k',
          label: '10 000 à 20 000 hab.'
        },
        {
          value: '20kTo30k',
          label: '20 000 à 30 000 hab.'
        },
        {
          value: '30kTo50k',
          label: '30 000 à 50 000 hab.'
        },
        {
          value: '50kTo100k',
          label: '50 000 à 100 000 hab.'
        },
        {
          value: '100kTo200k',
          label: '100 000 à 200 000 hab.'
        },
        {
          value: 'over200k',
          label: 'Plus de 200 000 hab.'
        }
      ],
      typeOptions: [
        {
          label: 'Quartier',
          value: 'district'
        },
        {
          label: 'Bourg',
          value: 'town'
        },
        {
          label: 'Centre-Ville',
          value: 'cityCenter'
        },
        {
          label: 'Centre commercial / Gare',
          value: 'commercialCenter'
        },
        {
          label: 'Autres',
          value: 'autre',
          precision: true
        }
      ],
      groupmentOptions: [
        {
          label: 'Oui',
          value: 'oui'
        },
        {
          label: 'Non',
          value: 'non'
        },
        {
          label: 'Ne se prononce pas',
          value: 'empty'
        }
      ],
      privacyLocalOptions: [
        {
          value: 'true',
          label: 'Oui',
          sublevel: {
            modelname: 'subLevel',
            type: 'radio',
            // SubLevel Options "Oui"
            options: [
              {
                label: 'Local de confidentialité dédié aux entretiens / soins',
                value: 'forHealth'
              },
              {
                label:
                  'Local de confidentialité qui est également le local d’orthopédie',
                value: 'forOrthopedics'
              }
            ]
          }
        },
        {
          value: 'false',
          label: 'Non'
        }
      ],
      proposedServiceOptions: [
        {
          value: 'pharmaceuticalInterviews',
          label: 'Entretiens pharmaceutiques / Bilans de médication',
          sublevel: {
            modelname: 'pharmaceuticalInterviews',
            type: 'checkbox',
            options: [
              {
                label:
                  'Conventionnés(Anticoagulants oraux, Corticoïdes inhalés pour l’asthme)',
                value: 'forHealth'
              },
              {
                label: 'Bilans partagés de médication',
                value: 'shareReview'
              },
              {
                label:
                  'Entretiens non conventionnés (entretien motivationnel d’aide au sevrage tabagique, pollin’air, …)',
                value: 'nonConventionalInterviews'
              }
            ]
          }
        },
        {
          value: 'trodUtilisation',
          label: 'Utilisation de TRODs',
          sublevel: {
            modelname: 'trodUtilisation',
            type: 'checkbox',
            options: [
              {
                label:
                  'Evaluation de la glycémie (dans le cadre de campagne de dépistage)',
                value: 'forHealth'
              },
              {
                label: 'Orientation diagnostic angines strepto A',
                value: 'angines'
              },
              {
                label: 'Orientation diagnostic COVID-19 (test antigénique)',
                value: 'trod'
              },
              {
                label: 'Orientation diagnostic grippe',
                value: 'grippe'
              },
              {
                label: 'Evaluation de la sérologie COVID-19',
                value: 'covid'
              }
            ]
          }
        },
        {
          value: 'constantsCheckboxes',
          label: 'Prise de constante',
          sublevel: {
            modelname: 'constantsCheckboxes',
            type: 'checkbox',
            options: [
              {
                label: 'Mesure de la tension artérielle',
                value: 'forHealth'
              },
              {
                label: 'Mesure poids / IMC',
                value: 'weight'
              },
              {
                label: 'Mesure de la saturation 02',
                value: 'saturation'
              },
              {
                label: 'Mesure de la température ',
                value: 'temperature'
              }
            ]
          }
        },
        { value: 'conseils', label: 'Conseils autotests' },
        {
          value: 'therapeuticParticipation',
          label: 'Participation à l’éducation thérapeutique des patients'
        },
        {
          value: 'teleconsultation',
          label: 'Possibilité de téléconsultation à l’officine'
        },
        { value: 'adaptedDispensation', label: 'Dispensation adaptée' },
        { value: 'other', label: 'Autres', precision: true }
      ],
      frequencyOptions: [
        {
          label: 'Jamais',
          value: 'never'
        },
        {
          label: 'Quelques cas par semaine',
          value: 'some'
        },
        {
          label: '1-2 par jour',
          value: 'oneTwo'
        },
        {
          label: '3-5 par jour',
          value: 'threeFive'
        },
        {
          label: '6-10 par jour',
          value: 'sixTen'
        },
        {
          label: '>10 par jour',
          value: 'moreTen'
        }
      ],
      collaborationOptions: [
        {
          value: 'true',
          label: 'Oui',
          sublevel: {
            modelname: 'subLevel',
            type: 'checkbox',
            // SubLevel Options "Oui"
            options: [
              {
                label: 'Maison de santé pluri professionnelle',
                value: 'heatlhHouse'
              },
              {
                label: 'CPTS',
                value: 'cpts'
              },
              {
                label: 'ESP',
                value: 'esp'
              },
              {
                label: 'Réseau de santé',
                value: 'healthNetwork'
              }
            ]
          }
        },
        {
          value: 'false',
          label: 'Non'
        }
      ],
      crisisImpactOptions: [
        {
          value: 'notCovidLinked',
          label: 'Demandes <u>non-liées</u> à la COVID-19 ?',
          sublevel: {
            modelname: 'notCovidLinked',
            type: 'radio',
            options: [
              {
                value: 'augmentation',
                label: 'Oui, une augmentation',
                sublevel: {
                  modelname: 'subLevelYes',
                  type: 'checkbox',
                  title: 'Si oui pour quelles raisons principalement ? ',
                  options: [
                    {
                      label:
                        'Augmentation de la fréquentation générale de l’officine',
                      value: 'frequencyAugment'
                    },
                    {
                      label:
                        'Peur des usagers de contracter le virus dans les cabinets médicaux',
                      value: 'usersAfraid'
                    },
                    {
                      label: 'Autres',
                      value: 'others'
                    }
                  ]
                }
              },
              {
                value: 'diminution',
                label: 'Non, une diminution',
                title: 'Pour quelle(s) raison(s) principalement ? ',
                sublevel: {
                  modelname: 'subLevelNo',
                  type: 'checkbox',
                  options: [
                    {
                      label: 'Baisse de fréquentation générale de l’officine',
                      value: 'frequencyDiminution'
                    },
                    {
                      label:
                        'Peur des usagers de contracter le virus dans les cabinets médicaux',
                      value: 'usersVirusAfraid'
                    },
                    {
                      label: 'Autres',
                      value: 'others'
                    }
                  ]
                }
              },
              { value: 'noImpact', label: "Pas d'impact" }
            ]
          }
        },
        {
          value: 'covidLinked',
          label: 'Demandes <u>liées</u> à la COVID-19 ?',
          sublevel: {
            modelname: 'covidLinked',
            type: 'radio',
            options: [
              {
                value: 'augmentation',
                label: 'Oui, une augmentation',
                sublevel: {
                  modelname: 'subLevelYes',
                  type: 'checkbox',
                  title: 'Quel est le contexte de ces demandes ? ',
                  options: [
                    {
                      label: 'Symptômes potentiellement liés à la COVID-19',
                      value: 'covidSymptom'
                    },
                    {
                      label: 'Craintes spécifiques liées à la COVID-19',
                      value: 'usersVirusAfraid'
                    },
                    {
                      label:
                        'Peur de contracter le virus dans un établissement de santé ou un cabinet ',
                      value: 'usersVirusInOfficineAfraid'
                    },
                    {
                      label:
                        'Demande en lien avec le dépistage de la COVID-19 (PCR, sérologie, …)',
                      value: 'demandsForCovid'
                    },
                    {
                      label: 'Symptômes persistants post-COVID-19',
                      value: 'persistantSymptoms'
                    },
                    {
                      label: 'Autres',
                      value: 'others'
                    }
                  ]
                }
              },
              { value: 'noImpact', label: "Pas d'impact" }
            ]
          }
        }
      ],
      noCovidAugmentation: [],
      noCovidDiminution: [],
      covidAugmentation: []
    }
  },

  components: {
    'radio-form': () => import('../../../core/formFactory/radioForm'),
    'checkbox-form': () => import('../../../core/formFactory/checkboxesForm')
  },

  methods: {
    changeValue() {
      this.inter === true
    },
    isValidForm() {
      if (this.$refs.formDescription.validate()) {
        this.activeProps = this.active
        this.activeProps++
        this.$emit('formdescription', { active: this.activeProps, valid: true })
        window.scrollTo(0, 0)
      }
    },
    goBack() {
      this.activeProps = this.active
      this.activeProps--
      this.$emit('formdescription', { active: this.activeProps })
    }
  }
}
</script>
