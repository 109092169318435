<template>
  <section>
    <v-container>
      <v-subheader class="text-center text-h6 font-weight-light pl-0">
        <v-icon>mdi-rhombus-medium-outline</v-icon> Modifier le profil
      </v-subheader>

      <v-col cols="12" class="">
        <btn
          class=""
          color="secondary darken-1"
          :filled="false"
          to="/edituser"
          :minWidth="'25%'"
          >Editer les informations</btn
        >
      </v-col>
      <v-col cols="12" class="">
        <btn
          class=""
          color="secondary darken-1"
          to="/changepassword"
          :minWidth="'25%'"
          >Changer de mot de passe</btn
        >
      </v-col>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Actions',

  data() {
    return {}
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  components: {
    btn: () => import('../../../core/BasicBtn.vue')
  }
}
</script>
