<template>
  <v-container>
    <v-form ref="formQualification" v-model="valid" lazy-validation>
      <!-- 1.	Votre âge ?   -->
      <v-row>
        <v-col cols="12">
          <radio-form
            title="Votre âge ?"
            :options="ageOptions"
            :model="user.age"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- 2.	Votre sexe ?  -->
        <v-col cols="12">
          <radio-form
            title="Votre sexe ?"
            :options="sexOptions"
            :model="user.sex"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- 3.	Êtes-vous à jour de votre formation aux gestes & soins d’urgence (AFGSU) ? Cases à cocher -->

        <v-col cols="12">
          <radio-form
            title="Êtes-vous à jour de votre formation aux gestes & soins d’urgence
            (AFGSU) ?"
            :options="formationUrgencyOptions"
            :model="user.formationUrgency"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- 4.	Avez-vous reçu une formation dédiée à l’orientation des patients en cas de soins de premier recours ou d’urgences au sein de votre officine ? Cases à cocher -->
        <v-col cols="12">
          <radio-form
            title="Avez-vous reçu une formation dédiée à l’orientation des patients en
            cas de soins de premier recours ou d’urgences au sein de votre
            officine ?"
            :options="yesNoOptions"
            :model="user.formationPatientOrientation"
            :level="0"
          ></radio-form>
        </v-col>

        <!-- 5.	Quelle a été la durée totale approximative de(s) formation(s) que vous avez reçue(s) ?  -->
        <v-expand-transition>
          <v-col cols="12" v-show="showFormations">
            <p class="ml-0 pl- 0 font-weight-medium mt-4 mb-1">
              Quelle a été la durée totale approximative de(s) formation(s) que
              vous avez reçue(s) ?
            </p>
            <radio-form
              v-if="showFormationU"
              title="Formation aux gestes & soins d’urgence (AFGSU)"
              :options="formationTimeOptions"
              :model="user.timeOfFormationAfgsu"
              :level="1"
            ></radio-form>
            <radio-form
              v-if="showFormationO"
              title="Formation à l’orientation des patients en cas de soins de premier
              recours ou d’urgences"
              :options="formationTimeOptions"
              :model="user.timeOfFormationOrientation"
              :level="1"
            ></radio-form>
          </v-col>
        </v-expand-transition>

        <!-- 6.	Quels types de formations vous semblent nécessaires pour que votre équipe puisse mieux répondre aux attentes et orienter les patients en cas de soins de 1er recours ou d’urgence à l’officine ? (Cases à cocher) -->
        <v-col cols="12">
          <checkbox-form
            title="Quels types de formations vous semblent nécessaires pour que votre
            équipe puisse mieux répondre aux attentes et orienter les patients
            en cas de soins de 1er recours ou d’urgence à l’officine ?"
            :options="necessaryTypeOfFormationOptions"
            :model="user.necessaryTypeOfFormation"
            :level="0"
          ></checkbox-form>
        </v-col>

        <!-- 7.	Êtes-vous favorable à une reconnaissance de la compétence du pharmacien pour le conseil / l’orientation des patients en cas de demandes de 1er recours non programmées ? Réponse unique -->
        <v-col cols="12">
          <radio-form
            title="Êtes-vous favorable à une reconnaissance de la compétence du
            pharmacien pour le conseil / l’orientation des patients en cas de
            demandes de 1er recours non programmées ?"
            :options="yesNoOptions"
            :model="user.favorableForRecognition"
            :level="0"
          ></radio-form>
        </v-col>
      </v-row>
      <v-btn color="primary" @click="isValidForm">
        Etape suivante
      </v-btn>
      <v-btn color="primary" @click="goBack" text>
        Etape précédente
      </v-btn>
    </v-form>
  </v-container>
</template>
<script>
export default {
  props: ['user', 'active'],

  data() {
    return {
      valid: true,
      activeProps: 0,

      ageOptions: [
        { label: '<30 ans', value: 'underThirty' },
        { label: '31/40 ans', value: 'thirtyForty' },
        { label: '41/50 ans', value: 'fortyFifty' },
        { label: '51/60 ans', value: 'fiftySixty' },
        { label: '60 ans', value: 'moreSixty' }
      ],
      sexOptions: [
        { value: 'female', label: 'Femme' },
        { value: 'male', label: 'Homme' }
      ],
      necessaryTypeOfFormationOptions: [
        {
          label:
            'Détection, prise en charge et orientation des cas suspects de COVID-19',
          value: 'suspectsDetection'
        },
        {
          label: 'Gestes de 1er secours (AFGSU) / Utilisation défibrillateur',
          value: 'firtsGesture'
        },
        {
          label:
            'Informations sur les prises en charge de certaines pathologies (ex. : Dermatologie, Ophtalmologie, ORL, Dentaire, …)',
          value: 'pathologiesInformation'
        },
        {
          label: 'Orientation des usagers en cas d’urgence à l’officine',
          value: 'orientationOfficine'
        },
        { label: 'Prise en charge des plaies', value: 'wound' },
        {
          label: 'Traitement aigu de la douleur',
          value: 'painTreatment'
        },
        {
          label: 'Autres',
          value: 'other',
          precision: true
        }
      ],
      formationUrgencyOptions: [
        {
          label: 'Oui',
          value: 'yes',
          sublevel: {
            title: 'Dans quel cadre l’avez-vous validée ? ',
            modelname: 'subLevelYes',
            type: 'checkbox',
            options: [
              { label: 'Au cours des études', value: 'study' },
              { label: 'Au sein de votre groupement', value: 'inGroupement' },
              { label: 'Au sein de votre officine', value: 'inOfficine' },
              { label: 'Formation continue / DPC', value: 'continueFormation' },
              { label: 'Autre', value: 'others' }
            ]
          }
        },
        {
          label: 'Non, mon AFGSU n’est plus valide (validité de 4ans)',
          value: 'noValid'
        },
        {
          label: 'Non, je n’ai jamais été titulaire de l’AFGSU',
          value: 'neverValid'
        }
      ],
      yesNoOptions: [
        { value: 'true', label: 'Oui' },
        { value: 'false', label: 'Non' }
      ],
      formationTimeOptions: [
        { label: '<2h', value: 'twoHours' },
        { label: '2-4h', value: 'fourHours' },
        { label: '0,5h-1j', value: 'oneDay' },
        { label: '1-3j', value: 'threeDays' },
        { label: '>3j', value: 'moreThanThreeDays' }
      ]
    }
  },
  // watch: {
  //   valid: function () {
  //     if (this.$refs.formQualification.validate()) {
  //       this.$emit('formqualification', true)
  //     }
  //   }
  // },

  components: {
    'radio-form': () => import('../../../core/formFactory/radioForm'),
    'checkbox-form': () => import('../../../core/formFactory/checkboxesForm')
  },

  computed: {
    showFormationO() {
      return this.user.formationPatientOrientation.content == 'true'
    },
    showFormationU() {
      return this.user.formationUrgency.content == 'yes'
    },
    showFormations() {
      return this.showFormationO || this.showFormationU
    }
  },

  methods: {
    isValidForm() {
      if (this.$refs.formQualification.validate()) {
        this.activeProps = this.active
        this.activeProps++
        this.$emit('formqualification', {
          active: this.activeProps,
          valid: true
        })
      }
    },
    goBack() {
      this.activeProps = this.active
      this.activeProps--
      this.$emit('formqualification', { active: this.activeProps })
    }
  }
}
</script>

<style scoped>
.col-12{
  padding-top: 0;
}
</style>
