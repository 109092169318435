import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL_API + 'api/auth/'

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        finess: user.finess,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data.user))
          localStorage.setItem('token', response.data.token)
        }
        return response.data
      })
      .catch(error => {
        throw error
      })
  }

  logout(user) {
    return axios
      .post(API_URL + 'logoutall', {
        headers: authHeader(),
        user
      })
      .then(response => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        return response
      })
      .catch(err => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        if (err.response) {
          return err.response
        } else {
          return err
        }
      })
  }

  register(user) {
    return axios
      .post(API_URL + 'signup', {
        user,
        url: process.env.VUE_APP_URL_FRONT
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }

  confirm(token) {
    return axios
      .put(API_URL + 'confirm', {
        token: token
      })
      .then(response => {
        return response
      })
      .catch(err => {
        if (err.response) {
          return err.response
        } else {
          return err
        }
      })
  }

  resend(token) {
    return axios
      .put(API_URL + 'confirm/resend', {
        token: token,
        url: process.env.VUE_APP_URL_FRONT
      })
      .then(response => {
        return response
      })
      .catch(err => {
        if (err.response) {
          return err.response
        } else {
          return err
        }
      })
  }
}

export default new AuthService()
