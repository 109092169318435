<template>
  <div>
    <p v-if="title != null" :class="getClassP(level)">{{ title }}</p>

    <v-radio-group
      v-model="model.content"
      :rules="rules"
      @change="clearOrphean($event)"
      class="mt-1"
    >
      <div v-for="elem in options" :key="elem.value">
        <v-radio :value="elem.value" :class="getClass(level)">
          <template v-slot:label>
            <div v-html="elem.label"></div>
          </template>
        </v-radio>

        <!-- sublevel -->
        <div
          v-if="
            elem.hasOwnProperty('sublevel') && elem.sublevel.type == 'checkbox'
          "
        >
          <v-expand-transition>
            <div v-show="model.content == elem.value">
              <checkboxesForm
                :title="elem.sublevel.title || null"
                :options="elem.sublevel.options"
                :model="model[elem.sublevel.modelname]"
                :level="level + 1"
                :isRequired="model.content == elem.value"
              ></checkboxesForm>
            </div>
          </v-expand-transition>
        </div>

        <div
          v-if="
            elem.hasOwnProperty('sublevel') && elem.sublevel.type == 'radio'
          "
        >
          <v-expand-transition>
            <div v-show="model.content == elem.value">
              <radioForm
                :title="elem.sublevel.title || null"
                :options="elem.sublevel.options"
                :model="model[elem.sublevel.modelname]"
                :level="level + 1"
                :isRequired="model.content == elem.value"
              ></radioForm>
            </div>
          </v-expand-transition>
        </div>

        <!-- precision -->
        <v-expand-transition>
          <v-text-field
            v-model="model.precision"
            v-if="elem.precision"
            v-show="model.content == elem.value"
            label="Précisez"
            outlined
            dense
            :class="getClass(level + 1)"
            :rules="inputRules(elem.value)"
          ></v-text-field>
        </v-expand-transition>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
/**
 * This component allows to create a radio form
 * from a list and bind it to the v-model given in
 * content.
 *
 * It allows to specifiy a sublevel, list or check-
 * boxes, and to add a precision field.
 *
 * Format du JSON en entrée:
 *  - sublevel: {
 *        modelname: 'sublevel',
 *        type: 'checkbox/radio',
 *        options: Array}
 *  - precision: true
 */
export default {
  name: 'radioForm',

  props: {
    title: { type: String, required: false },
    options: { type: Array, required: true },
    model: { type: Object, required: true },
    level: { type: Number, required: true },
    isRequired: { type: Boolean, default: true }
  },

  data: function() {
    return {}
  },

  computed: {
    rules() {
      return this.isRequired
        ? [
            v =>
              (typeof v !== 'undefined' && v !== '') ||
              'Veuillez sélectionner une valeur'
          ]
        : [() => true]
    }
  },

  components: {
    checkboxesForm: () => import('./checkboxesForm')
  },

  methods: {
    getClass(level) {
      return 'ml-' + level * 4 + ' pl-' + level * 4 + ' mb-0'
    },
    getClassP(level) {
      return (
        'ml-' +
        level * 4 +
        ' pl- ' +
        level * 4 +
        ' font-weight-medium mt-4 mb-1'
      )
    },
    inputRules(val) {
      return this.model.content == val ? [v => !!v || 'Champ requis'] : []
    },
    clearOrphean(_selection) {
      // parcourir le niveau actuel des options
      // quand l'option est différente de ce qui est selectionné
      // alors on clean le sous-niveau
      // console.log(_selection)
      // console.log(this.model)
      // console.log(this.options)

      this.options.forEach(element => {
        if (_selection !== element.value) {
          if (element.hasOwnProperty('sublevel')) {
            // console.log('Edit this.'+element.sublevel.modelname +'.content as '+element.sublevel.type)
            this.model[element.sublevel.modelname].content =
              'checkbox' == element.sublevel.type ? [] : ''
          }
          if (element.precision) {
            this.model.precision = ''
          }
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .v-messages {
  min-height: 0 !important;
}
</style>
