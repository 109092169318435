<template>
  <v-container fluid class="pa-0">
    <app-page-title
      title="Historique de saisie formulaire"
      subtitle="Pour votre officine"
      icon="table"
    >
    </app-page-title>
    <v-container>
      <history v-if="currentUser"></history>
      <div v-else>Vous n'êtes pas autorisés à voir cette page</div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'Historique',

  components: {
    history: () => import('../components/historyTable'),
    'app-page-title': () => import('../../../core/PageTitle')
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    showUserBoard() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Utilisateur')
      }
      return false
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Administrateur')
      }
      return false
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }
  }
}
</script>
