<template>
  <v-container fluid class="pa-0">
    <app-title title="Inscription Officine" subtitle="Formulaire de reseignements" icon="account-multiple-plus-outline"></app-title>
    <v-container>
      <v-stepper v-model="active" class="elevation-0">
        <v-stepper-header class="bg-grey font-weight-bold text-uppercase elevation-0">
          <v-stepper-step
            :complete="active > 1"
            step="1"
            :editable="active > 1"
          >
            Données d'identification
          </v-stepper-step>
          <v-stepper-step
            :complete="active > 2"
            step="2"
            :editable="active > 2"
          >
            Description de l’officine
          </v-stepper-step>
          <v-stepper-step
            :complete="active > 3"
            step="3"
            :editable="active > 3"
          >
            Qualification du titulaire
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-content step="1">
          <RegisterInfo
            :user="user"
            :active="active"
            @formregisterinfo="getValidationForRegisterInfo"
          ></RegisterInfo>
        </v-stepper-content>

        <v-stepper-content step="2">
          <Description
            :user="user"
            :active="active"
            @formdescription="getValidationForDescription"
          ></Description>
        </v-stepper-content>

        <v-stepper-content step="3">
          <Qualification
            :user="user"
            :active="active"
            @formqualification="getValidationForQualification"
          ></Qualification>
        </v-stepper-content>
      </v-stepper>
      <!-- Submit -->
      <v-spacer></v-spacer>
      <v-row>
        <!--Conditions-->
        <v-col cols="12" md="6">
          <v-alert
            v-if="
              this.validForRegisterInfo &&
                this.validForDescription &&
                this.validForQualification
            "
            border="left"
            colored-border
            type="info"
            elevation="2"
          >
            Vous avez désormais renseigné tous les éléments dont nous avions
            besoin ! Finalisez votre inscription en cliquant sur le bouton
            ci-dessous.
          </v-alert>
          <btn-app
            :disabled="
              !this.validForRegisterInfo ||
                !this.validForDescription ||
                !this.validForQualification
            "
            large
            class="mr-4"
            @click="dialog = true"
          >
            Inscription
          </btn-app>
          <v-dialog
            v-model="dialog"
            persistent
            max-width="400"
            scrollable
            v-if="!this.condition"
          >
            <v-card>
              <v-card-title class="headline">
                Mentions d'informations
              </v-card-title>
              <v-card-text>
                Veuillez lire et accepter les
                <a href="/mention" target="_blank">Mentions d'informations</a>.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <btn-app
                  color="error darken-1"
                  :filled="false"
                  @click="AcceptConditionToasted"
                >
                  Refuser
                </btn-app>
                <btn-app color="success" @click="validate">
                  Accepter
                </btn-app>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import User from '../../../models/user'
import RegisterInfo from '../components/RegisterInfo'
import Description from '../components/Description'
import Qualification from '../components/Qualification'

export default {
  name: 'Register',
  data() {
    return {
      user: new User(null),
      rules: {
        required: value => !!value || 'Champ requis.'
      },
      active: 1,
      dialog: false,
      condition: false,
      validForRegisterInfo: false,
      validForDescription: false,
      validForQualification: false
    }
  },
  components: {
    RegisterInfo,
    Description,
    Qualification,
    'btn-app': () => import('../../../core/BasicBtn'),
    'app-title': () => import('../../../core/PageTitle')
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/profile')
    }
  },
  methods: {
    validate() {
      this.dialog = false
      this.condition = true
      // console.log('ici', this.condition);
      if (
        this.condition &&
        this.validForRegisterInfo &&
        this.validForDescription &&
        this.validForQualification
      ) {
        this.handleRegister()
      }
    },
    handleRegister() {
      // console.log(this.user)
      let message =
        'Merci beaucoup ! Vous êtes désormais inscrits sur la plateforme Demandes de Soins Non Programmés en Officine Grand Est. Vous allez désormais recevoir un mail pour finaliser votre inscription'

      this.$store
        .dispatch('auth/register', this.user)
        .then(
          response => {
            this.$toasted.show(message, {
              type: 'success',
              duration: '5000',
              position: 'top-center'
            })
            this.$router.push('/')
          },
          err => {
            // eslint-disable-next-line no-console
            this.$toasted.show(err.error.message, {
              type: 'error',
              position: 'top-center',
              duration: '5000',
              action: {
                text: 'Supprimer',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                }
              }
            })
          }
        )
        .catch()
    },
    getValidationForRegisterInfo(value) {
      if (value.hasOwnProperty('valid')) {
        this.validForRegisterInfo = value.valid
      }
      this.active = value.active
    },
    getValidationForDescription(value) {
      if (value.hasOwnProperty('valid')) {
        this.validForDescription = value.valid
      }
      this.active = value.active
    },
    getValidationForQualification(value) {
      if (value.hasOwnProperty('valid')) {
        this.validForQualification = value.valid
      }
      this.active = value.active
    },
    AcceptConditionToasted() {
      this.dialog = false
      this.condition = false
      this.$toasted.show('Vous devez accepter les conditions légales', {
        type: 'error',
        position: 'top-center',
        duration: '5000',
        action: {
          text: 'Supprimer',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    showDialogOrValidate() {
      if (!this.condition) {
       this.dialog = true 
      } else {
        this.validate();
      }
    }
  }
}
</script>

<style>
.bg-grey {
  background-color: rgb(223, 223, 223);
}
</style>
