export default class Formulaire {
  constructor() {
    this.pickupDate = {
      type: '',
      date: new Date()
    };

    this.patientAge;

    this.country = {
      content: 'french',
      precision: ''
    };

    this.sex;

    this.c2s;

    this.ald;

    this.patientDoctor;

    this.knownPatient;

    this.reason = {
      content: [],
      precision: ''
    };

    this.whoReferred = {
      content: '',
      precision: '',
      subLevel: {
        content: [],
        precision: '',
        precisiondoctor: ''
      }
    };

    this.accessDifficulties = {
      content: undefined, //yes/no
      subLevel: {
        content: [], //ii
        subLevel: {
          content: [], //1,2
          precision: ''
        }
      }
    };

    this.patientHealthStatus = {
      content: [],
      precision: '',
      subLevelA: {
        content: []
      },
      subLevelB: {
        content: []
      },
      subLevelE: {
        content: []
      }
    };

    this.performedActs = {
      technical: {
        content: undefined,
        subLevel: {
          content: [],
          subLevelI: {
            content: [],
            precision: ''
          },
          subLevelII: {
            content: [],
            precision: ''
          },
          subLevelIII: {
            content: [],
            precision: '',
            burnGravity: {
              content: ''
            }
          }
        }
      },
      productDispensed: {
        content: undefined,
        subLevel: {
          content: []
        }
      },
      adviceProvided: {
        content: undefined,
        subLevel: {
          content: [],
          precision: '',
          subLevel: {
            content: ''
          }
        }
      },
      otherAct: {
        content: undefined,
        precision: ''
      }
    };

    this.whenTakeCare = {
      content: ''
    };

    this.whoTakeCare = {
      content: [],
      precision: ''
    };

    this.whereTakeCare = {
      content: [],
      precision: ''
    };

    this.durationTakeCare = 0;

    this.recommendedOrientation = {
      content: [],
      subLevelA: {
        content: '',
        subLevel: {
          content: []
        }
      },
      subLevelB: {
        content: '',
        subLevel: {
          content: '',
          precision: '',
          subLevel: {
            content: ''
          }
        }
      },
      subLevelD: {
        content: []
      }
    };

    this.healthProfessionalRelation = {
      content: ''
    };

    this.telemedicineTools = {
      content: [],
      subLevelTrod: {
        content: []
      }
    };

    this.telemedicineResults = {
      content: [],
      subLevelC: {
        content: ''
      },
      subLevelD: {
        content: '',
        precision: '',
        subLevel: {
          content: ''
        }
      }
    };

    this.sharedInfo = {
      content: undefined,
      subLevel: {
        content: [],
        subLevelI: {
          content: [],
          precision: ''
        },
        subLevelII: {
          content: []
        }
      }
    };

    this.howSharedInfo = {
      content: [],
      subLevel: {
        content: ''
      }
    };

    this.specificProtocol = {
      content: undefined,
      precision: ''
    };
  }
}
