<template>
  <centered-page>
    <v-card class="mx-auto">
      <v-card-title class="headline primary white--text" v-if="messageOk">
        {{ messageOk }}
      </v-card-title>

      <v-card-title class="headline warning white--text" v-if="messageKo">
        {{ messageKo }}
      </v-card-title>

      <v-card-text class="mt-4" v-if="messageOk">
        Validation de l'email en effectuée. Veuillez patienter quelques secondes,
        vous allez être redirigé vers la page de connexion.
      </v-card-text>

      <v-card-text class="mt-4" v-if="messageKo">
          Votre adresse mail n'a pas été validée. <br />
          Cliquez sur Renvoyer pour recevoir un nouveau mail de confirmation.
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="messageKo"
          color="primary"
          elevation="0"
          class="mx-auto"
          outlined
          @click="resend"
        >
          Renvoyer
        </v-btn>
      </v-card-actions>
    </v-card>
  </centered-page>
</template>

<script>
import AuthService from '../../../services/auth.service'

export default {
  name: 'Confirmation',

  data: () => ({
    messageKo: '',
    messageOk: '',
    token: ''
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    }
  },

  components: {
    'centered-page': () => import('../../../core/CenteredFullPage')
  },

  mounted() {
    this.token = this.$route.params.token
    var _this = this
    AuthService.confirm(this.token).then(response => {
      if (response.status == 200) {
        this.messageOk = response.data.message
        this.messageKo = ''
        setTimeout(function() {
          _this.$router.push('/login')
        }, 5000)
      } else if (response.status == 498) {
        this.messageOk = ''
        this.messageKo = response.data.message
      }
    })
  },
  methods: {
    resend() {
      AuthService.resend(this.token).then(response => {
        this.$toasted.show(response.data.message, {
          type: 'success',
          duration: '5000',
          position: 'top-center'
        })
        if (response.status == 200) {
          this.$router.push('/login')
        }
      })
    }
  }
}
</script>
