<template>
  <v-container class="pa-0" fluid>
    <page-title
      title="Foire aux questions"
      subtitle="Suite au premier webinaire"
      icon="comment-question-outline"
    ></page-title>
    <section>
      <v-container>
        <div v-for="(item, index) in questions" :key="index" class="mb-10">
          <strong>
            {{ item.question }}
          </strong>
          <p v-html="item.answer" class="mt-4"></p>
        </div>
      </v-container>
    </section>
    <section class="grey lighten-4">
      <v-container class="py-12">
        <p>
          Pour tout complément d’information ou question vous pouvez contacter :
        </p>
        <ul>
          <li>
            La hotline Tous Pour La Santé :
            <a href="tel:0645465846">06 45 46 58 46</a>
          </li>
          <li>
            L’URPS :
            <a href="mailto:secretariat@urpspharmaciensgrandest.fr">
              secretariat@urpspharmaciensgrandest.fr
            </a>
            ou <a href="tel:0953734534">09 53 73 45 34</a>
          </li>
        </ul>
      </v-container>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'FrequentlyAskedQuestions',

  data: () => ({
    questions: [
      {
        question:
          '1.	Où puis-je trouver le protocole de l’enquête de demandes de soins non programmées en officine ?',
        answer:
          'Vous pouvez retrouver le protocole sur l’interface de recueil <a href="https://snpofficinesgrandest.touspourlasante.tv/">https://snpofficinesgrandest.touspourlasante.tv/</a> et sur le site de l’URPS Pharmaciens Grand Est <a href="https://www.urpspharmaciensgrandest.fr/fr/articles/138">https://www.urpspharmaciensgrandest.fr/fr/articles/138</a>'
      },

      {
        question:
          '2.	A partir de quelle date puis-je m’enregistrer sur la plateforme de recueil en ligne pour participer à l’étude ?',
        answer:
          'Un lien vous sera envoyé avant le 19/11/2020 afin que vous puissiez vous enregistrer au préalable. Par la suite après votre inscription auprès de l’URPS Pharmaciens par le retour de votre charte d’engagement (<a href="mailto:secretariat@urpspharmaciensgrandest.fr">secretariat@urpspharmaciensgrandest.fr</a>) vous pourrez vous enregistrer pour rejoindre la plateforme à tout moment.'
      },

      {
        question: '3.	Comment se passe l’inscription à la plateforme ?',
        answer:
          'Pour vous inscrire il suffit d’enregistrer votre officine avec son numéro <u>Finess</u> et un <u>mail</u>, vous recevez ensuite un lien par mail afin de valider définitivement l’inscription et d’activer le compte. <br> Une fois le compte activé, vous devrez renseigner le profil de la pharmacie par un questionnaire à remplir une seule fois au début de l’étude. <br> S’il survenait des changements interne à l’officine en cours d’enquête (opérateur supplémentaire, CPTS, groupement…), vous avez toujours la possibilité d’éditer le profil et de le modifier.'
      },

      {
        question:
          '4.	L’arrivée des étudiants de 6ème année de pharmacie dans les officines s’effectuant en janvier 2021, est-il possible pour une pharmacie non inscrite de rejoindre l’étude à ce moment-là ?',
        answer:
          'Oui, vous pouvez rejoindre l’enquête à tout moment. Vous pouvez visualiser le webinaire d’information du 03/11/2020 en ligne à l’accueil de la plateforme de recueil : <a href="https://snpofficinesgrandest.touspourlasante.tv/">https://snpofficinesgrandest.touspourlasante.tv/</a>  <br>      Vous pourrez vous inscrire à l’étude par le biais de l’URPS Pharmaciens en renvoyant votre charte d’engagement : <a href="mailto:secretariat@urpspharmaciensgrandest.fr">secretariat@urpspharmaciensgrandest.fr</a> '
      },

      {
        question:
          '5.	Plusieurs opérateurs de la même pharmacie peuvent-ils enregistrer des données sur la plateforme de recueil ?',
        answer:
          'Oui plusieurs opérateurs (pharmacien titulaire et adjoint, préparateur, étudiant en 6ème année) peuvent renseigner les données mais un seul identifiant est attribué par officine à l’inscription.'
      },

      {
        question:
          '6.	Les périodes de recueil de données se passent le matin, l’après-midi, les deux ?',
        answer:
          'Le recueil ne s’effectue que sur des demi-journées ou matin ou après-midi. Le calendrier inclus dans le protocole ou disponible sur l’interface de recueil indique pour chaque demi-journée s’il s’agit du matin ou de l’après-midi.'
      },

      {
        question:
          '7.	La saisie informatique peut-elle se faire à une date ultérieure à la demi-journée de recueil des données identifiée sur le calendrier ?',
        answer:
          'Oui. Vous avez 2 possibilités pour enregistrer vos données sur la plateforme : <ul><li>Sur l’interface directement le jour de recueil</li><li>En différé au moment où vous le souhaitez : pour cela vous pouvez imprimer le formulaire PDF disponible en téléchargement sur la plateforme pour enregistrer ensuite vos données. En début de formulaire il vous suffira de renseigner la date de la demi-journée de recueil dont il s’agit.</li></ul>'
      },

      {
        question:
          '8.	Les dates des gardes étant propres à chaque officine, comment puis-je savoir à quel moment je dois recueillir des données ?',
        answer:
          'Les deux périodes de recueil de demandes de soins non programmées prévues sur des gardes (une garde en journée dimanche ou jour férié et une garde de nuit) sont hors calendrier prédéfini, ce sont des dates libres que vous choisirez vous-même selon votre exercice.<br> En complétant le formulaire, dès la première question, vous indiquerez cette date.'
      },

      {
        question:
          '9.	Le recueil de données par demi-journée doit-il être exhaustif ou faut-il sélectionner les cas ?',
        answer:
          'L’étude se veut le plus complète possible afin d’obtenir une représentation du travail du pharmacien au plus juste, cela correspond donc à renseigner toutes les demandes que vous recevrez durant chaque demi-journée de recueil répondant à la définition du protocole, sans que vous ne procédiez à une quelconque sélection.'
      },

      {
        question:
          '10.	Peut-on inclure dans l’enquête des patients qui n’ont pas ou plus de médecin traitant ?',
        answer:
          'Oui, en remplissant le formulaire vous trouverez la case correspondante à renseigner.'
      },

      {
        question:
          '11.	Des demandes de patients en complément de leur prescription peuvent-elles être enregistrées dans l’étude ?',
        answer:
          'Oui, si ces demandes répondent à la définition des demandes de soins non programmées du protocole il est important de les renseigner.'
      },

      {
        question:
          '12.	La demande de prendre rendez-vous avec un professionnel de santé (généraliste, spécialiste, infirmière…) pour un patient correspond-elle à une de demande de soins non programmée en officine ?',
        answer:
          'Oui, vous pourrez renseigner cet élément dans le formulaire au niveau de la prise en charge des patients.'
      },

      {
        question:
          '13.	Il peut être parfois délicat de poser certaines questions aux patients notamment celles concernant le régime de la personne (ALD, CMU, AME, C2S…), que faire dans ce cas ?',
        answer:
          'En remplissant le formulaire vous avez toujours la possibilité pour certaines questions de répondre par « Ne sait pas ».'
      },

      {
        question:
          '14.	Que puis-je faire si j’ai oublié de renseigner un élément ou que j’ai commis une erreur après validation du formulaire patient ?',
        answer:
          'Vous avez toujours la possibilité de modifier vos formulaires déjà enregistrés. Dans l’onglet « Historique » vous retrouvez tous vos enregistrements, vous pouvez les éditer et les actualiser.'
      },

      {
        question:
          '15.	Si la fréquentation de l’officine est telle que cela ne me permet pas de rapporter tous les cas de demandes de soins non programmées que puis-je faire ?',
        answer:
          'A l’issue de l’enquête un questionnaire vous sera envoyé dans lequel vous pourrez exprimer vos difficultés, vos ressentis et une appréciation de l’exhaustivité du rapport des cas durant l’étude.'
      },

      {
        question:
          '16.  Que faire si mon officine est fermée lors d’une demi-journée de recueil de données programmée par l’URPS Pharmaciens (par ex : Lundi, Samedi) ?',
        answer:
          '<ul><li>Dans le cadre de la méthodologie sélectionnée, et afin de ne pas biaiser l’étude, nous ne souhaitons recueillir que les demandes de soins programmées les demi-journées de collecte : il ne sera donc pas possible pour vous de participer à cette session.</li><li>Sur le plan de la participation/rémunération de votre officine, il a été anticipé que certaines officines ferment certains créneaux de la semaine, ainsi, vous ne serez pas pénalisé si vous participez aux sessions se déroulant tous les autres jours de la semaine.</li></ul>'
      }
    ]
  }),

  components: {
    // 'aaaa': () => import('../../../core/aaaa.vue'),
    'page-title': () => import('../../../core/PageTitle.vue'),
    'my-title': () => import('../../../core/Title.vue'),
    'app-calendar': () => import('../../../core/Calendar.vue')
  }
}
</script>
