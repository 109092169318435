<template>
  <div>
    <checkboxForm
      title="Où a été réalisée la prise en charge au sein de l’officine ?"
      :options="whereTakeCareOptions"
      :model="whereTakeCare"
      :level="0"
    ></checkboxForm>
  </div>
</template>

<script>
export default {
  name: 'whereTakeCareForm',
  props: {
    whereTakeCare: { type: Object, required: true }
  },
  components: {
    checkboxForm: () => import('../../../core/formFactory/checkboxesForm')
  },
  data: function() {
    return {
      whereTakeCareOptions: [
        {
          value: 'desk',
          label: 'Au comptoir'
        },
        {
          value: 'orthopedicPremise',
          label: 'Local orthopédie'
        },
        {
          value: 'confidentialityPremises',
          label: 'Local de confidentialité'
        },
        {
          value: 'other',
          label: 'Autre ',
          precision: true
        }
      ]
    };
  }
};
</script>
