<template>
  <div>
    <checkboxForm
      title="Quels ont été les résultats de la téléconsultation ?"
      :options="telemedicineResultsOptions"
      :model="telemedicineResults"
      :level="0"
      v-if="display"
    ></checkboxForm>
  </div>
</template>

<script>
export default {
  name: 'telemedicineResultsForm',
  props: {
    telemedicineResults: { type: Object, required: true },
    display: { type: Boolean, required: true }
  },
  components: {
    checkboxForm: () => import('../../../core/formFactory/checkboxesForm')
  },
  data: function() {
    return {
      telemedicineResultsOptions: [
        {
          value: 'prescription',
          label: 'Ordonnance'
        },
        {
          value: 'advices',
          label: 'Conseils'
        },
        {
          value: 'emergencyOrientation',
          label:
            'Orientation vers un service d’urgence/régulation/appel d’urgence',
          sublevel: {
            modelname: 'subLevelC',
            type: 'radio',
            options: [
              {
                value: 'samu',
                label: 'SAMU'
              },
              {
                value: 'emergency',
                label: 'Service des urgences'
              },
              {
                value: 'otherPec',
                label:
                  'Autre entité prenant en charge les soins non programmés par ex CMSI (Centre Médical de Soins Immédiats)'
              }
            ]
          }
        },
        {
          value: 'healthProfessionnalOrientation',
          label: 'Orientation vers un professionnel de santé',
          sublevel: {
            modelname: 'subLevelD',
            type: 'radio',
            options: [
              {
                value: 'doctor',
                label: 'Médecin',
                sublevel: {
                  modelname: 'subLevel',
                  type: 'radio',
                  options: [
                    {
                      value: 'generalist',
                      label: 'Médecin généraliste'
                    },
                    {
                      value: 'specialist',
                      label: 'Médecin spécialiste'
                    }
                  ]
                }
              },

              {
                value: 'nurse',
                label: 'Infirmier'
              },
              {
                value: 'kine',
                label: 'Kinésithérapeute'
              },
              {
                value: 'biologicalLaboratory',
                label: 'Laboratoire de Biologie'
              },
              {
                value: 'midwife',
                label: 'Sage-femme'
              },
              {
                value: 'dentalChir',
                label: 'Chirurgien-Dentiste'
              },
              {
                value: 'pediPodo',
                label: 'Pédicure-podologue'
              },
              {
                value: 'other',
                label: 'Autre',
                precision: true
              }
            ]
          }
        },
        {
          value: 'laboratoryPrescription',
          label: 'Prescription pour laboratoire de biologie'
        },
        {
          value: 'informationAttendingPractitioner',
          label: 'Information du médecin traitant'
        }
      ]
    }
  }
}
</script>
