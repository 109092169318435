<template>
  <v-container fluid class="pa-0">
    <app-page-title
      title="Saisie formulaire"
      subtitle="Temps de remplissage : ~2-5 minutes"
      icon="file-document-outline"
    >
      <btn
        href="/pdf/Questionnaire patient à imprimer - Plateforme Soins Non Programmés.pdf"
        target="_blank"
        download
        large
        :icon="'mdi-download'"
        :filled="false"
      >
        Télécharger PDF
      </btn>
    </app-page-title>

    <v-container>
      <!-- Stepper de rappel -->
      <v-col col="12" md="7" class="mx-auto">
        <v-stepper v-model="active" class="elevation-0">
          <v-stepper-header>
            <v-stepper-step
              step="1"
              :complete="validated.includes(1)"
            ></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="2"
              :complete="validated.includes(2)"
            ></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="3"
              :complete="validated.includes(3)"
            ></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="4"
              :complete="validated.includes(4)"
            ></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="5"
              :complete="validated.includes(5)"
            ></v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>

      <v-form ref="formulaireCasPatient" v-model="isValid" lazy-validation>
        <v-stepper v-model="active" vertical class="elevation-0">
          <!-- 0 -->
          <v-stepper-step
            step="1"
            :complete="active > 1"
            :editable="active < 1"
            class="bg-grey font-weight-bold text-uppercase"
          >
            Informations préliminaires
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-form ref="stepForm0" v-model="isValidForm0" lazy-validation>
              <pickupDateForm :pickupDate="answers.pickupDate"></pickupDateForm>
              <demographicDataForm :answers="answers"></demographicDataForm>
              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateForm0">
                    Continuer
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- A -->
          <v-stepper-step
            step="2"
            :complete="active > 2"
            class="bg-grey font-weight-bold text-uppercase"
          >
            Motif de la visite à l’officine
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-form ref="stepFormA" v-model="isValidFormA" lazy-validation>
              <visitReasonsForm :reasons="answers.reason"></visitReasonsForm>
              <whoReferredForm
                :whoReferred="answers.whoReferred"
              ></whoReferredForm>
              <accessDifficultyForm
                :accessDifficulty="answers.accessDifficulties"
              ></accessDifficultyForm>
              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateFormA">
                    Continuer
                  </v-btn>

                  <v-btn text @click="active = active - 1">
                    Retour
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- B -->
          <v-stepper-step
            step="3"
            :complete="active > 3"
            class="bg-grey font-weight-bold text-uppercase"
          >
            Recueil d’informations par le pharmacien (questions)
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-form ref="stepFormB" v-model="isValidFormB" lazy-validation>
              <patientHealthStatusForm
                :patientHealthStatus="answers.patientHealthStatus"
              ></patientHealthStatusForm>

              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateFormB">
                    Continuer
                  </v-btn>

                  <v-btn text @click="active = active - 1">
                    Retour
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- C -->
          <v-stepper-step
            step="4"
            :complete="active > 4"
            class="bg-grey font-weight-bold text-uppercase"
          >
            Actes et prise en charge
          </v-stepper-step>

          <v-stepper-content step="4">
            <v-form ref="stepFormC" v-model="isValidFormC" lazy-validation>
              <performedActsForm
                :performedActs="answers.performedActs"
              ></performedActsForm>
              <whoTakeCareForm
                :whoTakeCare="answers.whoTakeCare"
              ></whoTakeCareForm>
              <whereTakeCareForm
                :whereTakeCare="answers.whereTakeCare"
              ></whereTakeCareForm>
              <durationTakeCareForm
                :formulaire="answers"
              ></durationTakeCareForm>

              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateFormC">
                    Continuer
                  </v-btn>

                  <v-btn text @click="active = active - 1">
                    Retour
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- D -->
          <v-stepper-step
            step="5"
            :complete="active > 5"
            class="bg-grey font-weight-bold text-uppercase"
          >
            Orientation
          </v-stepper-step>

          <v-stepper-content step="5">
            <v-form ref="stepFormD" v-model="isValidFormD" lazy-validation>
              <recommendedOrientationForm
                :recommendedOrientation="answers.recommendedOrientation"
              ></recommendedOrientationForm>
              <healthProfessionalRelationForm
                :healthProfessionalRelation="answers.healthProfessionalRelation"
                :display="
                  answers.recommendedOrientation.content.includes(
                    'heathprofessionnal'
                  )
                "
              ></healthProfessionalRelationForm>
              <telemedicineToolsForm
                :telemedicineTools="answers.telemedicineTools"
                :display="
                  answers.recommendedOrientation.subLevelB.subLevel.subLevel
                    .content === 'teleConsultingSide'
                "
              ></telemedicineToolsForm>
              <telemedicineResultsForm
                :telemedicineResults="answers.telemedicineResults"
                :display="
                  answers.recommendedOrientation.subLevelB.subLevel.subLevel
                    .content === 'teleConsultingSide'
                "
              ></telemedicineResultsForm>
              <sharedInfoForm :sharedInfo="answers.sharedInfo"></sharedInfoForm>
              <howSharedInfoForm
                :howSharedInfo="answers.howSharedInfo"
                :display="answers.sharedInfo.content == true"
              ></howSharedInfoForm>
              <specificProtocolForm
                :specificProtocol="answers.specificProtocol"
              ></specificProtocolForm>

              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateFormD">
                    Continuer
                  </v-btn>

                  <v-btn text @click="active = active - 1">
                    Retour
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper>

        <v-row>
          <v-col cols="12" md="4">
            <btn
              :disabled="active < 6"
              large
              minWidth="220px"
              @click="validateAll"
            >
              Soumettre
            </btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import Formulaire from '../../../models/formulaire'
import FormulaireService from '../../../services/formulaire.service'
import pickupDateForm from '../components/pickupDateForm'
import demographicDataForm from '../components/demographicDataForm'
import visitReasonsForm from '../components/visitReasonsForm'
import whoReferredForm from '../components/whoReferredForm'
import accessDifficultyForm from '../components/accessDifficultyForm'
import patientHealthStatusForm from '../components/patientHealthStatusForm'
import performedActsForm from '../components/performedActsForm'
import whoTakeCareForm from '../components/whoTakeCareForm'
import whereTakeCareForm from '../components/whereTakeCareForm'
import recommendedOrientationForm from '../components/recommendedOrientationForm'
import durationTakeCareForm from '../components/durationTakeCareForm'
import healthProfessionalRelationForm from '../components/healthProfessionalRelationForm'
import telemedicineToolsForm from '../components/telemedicineToolsForm'
import telemedicineResultsForm from '../components/telemedicineResultsForm'
import sharedInfoForm from '../components/sharedInfoForm'
import howSharedInfoForm from '../components/howSharedInfoForm'
import specificProtocolForm from '../components/specificProtocolForm'
// import checkboxesForm from './formFactory/checkboxesForm';
// import radioForm from './formFactory/radioForm';
export default {
  name: 'formulairePatient',

  data: () => ({
    active: 0,
    answers: new Formulaire(),
    isValid: false,

    validated: [],
    isMainFormValid: false,
    isValidForm0: false,
    isValidFormA: false,
    isValidFormB: false,
    isValidFormC: false,
    isValidFormD: false,

    messageOk: '',
    messageKo: '',
    alert: '',
    status: '',
    pickerDisplay: false,
    rulesRequired: [v => !!v || 'Champ requis'],
    successful: false,
    sponsorDates: [{ value: 'aaa', text: '20/02/1000' }],
    pdf: '/src/assets/cas_patient.pdf'
  }),

  mounted() {
    this.active = 1
    if (!this.$store.state.auth.user) {
      this.$router.push('/login')
    }
  },

  components: {
    pickupDateForm,
    demographicDataForm,
    visitReasonsForm,
    whoReferredForm,
    accessDifficultyForm,
    patientHealthStatusForm,
    performedActsForm,
    whoTakeCareForm,
    whereTakeCareForm,
    recommendedOrientationForm,
    durationTakeCareForm,
    healthProfessionalRelationForm,
    telemedicineToolsForm,
    telemedicineResultsForm,
    sharedInfoForm,
    howSharedInfoForm,
    specificProtocolForm,
    'app-page-title': () => import('../../../core/PageTitle'),
    btn: () => import('../../../core/BasicBtn.vue')

    // checkboxesForm,
    // radioForm
  },

  methods: {
    validateAll() {
      if (this.$refs.formulaireCasPatient.validate()) {
        // this.alert = true
        // console.log(this.currentUser.finess)
        FormulaireService.postFormulaire(this.answers)
          .then(response => {
            this.$toasted.show(response.message, {
              type: 'success',
              duration: '5000',
              position: 'top-center'
            })
            // this.reset()
            this.$router.push('/')
          })
          .catch(err => {
            console.log(err)
            this.$toasted.show(err.error, {
              type: 'error',
              position: 'top-center',
              duration: '5000',
              action: {
                text: 'Cancel',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                }
              }
            })
          })
      }
    },
    reset() {
      this.active = 0
      // this.answers = new Formulaire()
      this.$refs.formulaireCasPatient.reset()
      this.isValid = false
    },
    validateForm0() {
      if (this.$refs.stepForm0.validate()) {
        this.isValidForm0 = true
        this.active = 2
        this.validated.push(1)
      }
    },
    validateFormA() {
      if (this.$refs.stepFormA.validate()) {
        this.isValidFormA = true
        this.active = 3
        this.validated.push(2)
      }
    },
    validateFormB() {
      if (this.$refs.stepFormB.validate()) {
        this.isValidFormB = true
        this.active = 4
        this.validated.push(3)
      }
    },
    validateFormC() {
      if (this.$refs.stepFormC.validate()) {
        this.isValidFormC = true
        this.active = 5
        this.validated.push(4)
      }
    },
    validateFormD() {
      if (this.$refs.stepFormD.validate()) {
        this.isValidFormD = true
        this.active = 6
        this.validated.push(5)
      }
    },

    showPdf() {
      // var pdf = '/src/assets/cas_patient.pdf'
      window.open(this.pdf, '_blank')
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    }
  }
}
</script>

<style>
.bg-grey {
  background-color: rgb(223, 223, 223);
}
</style>
