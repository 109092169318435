<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import Default from "./layouts/Default.vue"
import NoBorder from "./layouts/NoBorder.vue"
import Sidebar from "./layouts/Sidebar.vue"
import axios from "axios"

export default {
  name: "App",

  components: {
    "default-layout": Default,
    "no-border-layout": NoBorder,
    "sidebar-layout": Sidebar
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout"
    },
    currentUser() {
      return this.$store.state.auth.user
    }
  },
  created() {
    var _this = this
    axios.interceptors.response.use(undefined, function(err) {
      return new Promise(function(resolve, reject) {
        if (
          err.response.status === 401 &&
          _this.$store.state.auth.status.loggedIn
        ) {
          _this.$router.push("/logout").catch(() => {})
        }
        throw err
      })
    })
  }
}
</script>
