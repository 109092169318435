import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
//import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#394285',
        secondary: '#394281',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    }
  }
})
