<template>
  <div>
    <radioForm
      title="Votre prise en charge a-t-elle reposée sur un protocole spécifique (arbre décisionnels TROD, PharmaReco…) ?"
      :options="specificProtocolOptions"
      :model="specificProtocol"
      :level="0"
    ></radioForm>
  </div>
</template>

<script>
export default {
  name: 'specificProtocolForm',
  props: {
    specificProtocol: { type: Object, required: true }
  },
  components: {
    radioForm: () => import('../../../core/formFactory/radioForm')
  },
  data: function() {
    return {
      specificProtocolOptions: [
        {
          value: true,
          label: 'Oui',
          precision: true
        },
        {
          value: false,
          label: 'Non'
        }
      ]
    };
  }
};
</script>
