<template>
  <v-container
    fluid
    flex
    class="grey lighten-4 text-center height-100 d-flex flex-column pa-0 pt-4 pt-md-10"
  >
    <!-- TITRE -->
    <app-title class="mx-auto mb-5">SNP Officines Grand Est</app-title>

    <!-- CARD -->
    <v-card width="400" class="mx-auto elevation-4 pa-4 mb-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="">
          Connexion à votre compte
        </div>

        <v-spacer></v-spacer>

        <v-card-text class="">
          <v-col align="start">
            <div class="primary--text font-weight-bold mb-2">N° finess</div>
            <v-text-field
              label="Numéro Finess"
              name="finess"
              v-mask="'#########'"
              dense
              v-model="user.finess"
              type="tel"
              :rules="[rules.required]"
              counter
              maxlength="9"
              outlined
              solo
              placeholder="Numéro Finess"
            ></v-text-field>

            <div class="primary--text font-weight-bold mb-2">Mot de passe</div>
            <v-text-field
              v-model="user.password"
              label="Mot de passe"
              name="password"
              dense
              :append-icon="password_display ? 'mdi-eye' : 'mdi-eye-off'"
              :type="password_display ? 'text' : 'password'"
              @click:append="password_display = !password_display"
              :rules="[rules.required]"
              outlined
              solo
              placeholder="Mot de passe"
            ></v-text-field>
          </v-col>

          <v-col align="center">
            <app-btn :disabled="!valid" color="primary" @click="validate">
              Connexion
            </app-btn>
          </v-col>
        </v-card-text>

        <v-divider class="mx-12 mt-10"></v-divider>
        <v-row>
          <v-col cols="12" md="6" align="start">
            <router-link to="/register">
              <p text color="primary" class="ml-4">Inscription</p>
            </router-link>
          </v-col>
          <v-col cols="12" md="6" align="end">
            <router-link to="/resetpassword">
              <p text color="primary" class="mr-4">Mot de passe oublié</p>
            </router-link>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <!-- SMALL TEXT -->
    <div class="primary--text">
      <a href="/mention" class="text-decoration-none"
        >Mentions d'informations</a
      >
      ■
      <v-tooltip v-model="show" bottom max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <a v-bind="attrs" v-on="on">Première connexion ?</a>
        </template>
        <span>
          C’est votre première connexion ? Vous avez reçu un mail de
          confirmation suite à votre inscription, il est nécessaire de valider
          le lien contenu dans cet email afin de pouvoir vous connecter.
        </span>
      </v-tooltip>
    </div>

    <!-- LOGOS-->

    <!-- CLOSE -->
    <a @click="$router.push('/')" class="top">
      <v-icon x-large>mdi-close</v-icon>
    </a>
  </v-container>
</template>

<script>
import User from '../../../models/user'
import { mask } from 'vue-the-mask'

export default {
  name: 'Login',
  data() {
    return {
      user: new User(null),
      loading: false,
      message: '',
      valid: false,
      alert: false,
      show: false,
      password_display: false,
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },

  methods: {
    validate() {
      this.handleLogin()
    },
    handleLogin() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('auth/login', this.user)
          .then(response => {
            this.$toasted.show(response.message, {
              type: 'success',
              duration: '5000',
              position: 'top-center'
            })
            // if (response.isVerified) {
            //   this.$router.push('/profile')
            // }
            // console.log(response.firstConnection)
            if (response.firstConnection) {
              this.$router.push('/information')
            } else {
              this.$router.push('/')
            }
          })
          .catch(err => {
            // console.log(err)
            this.$toasted.show(err.error, {
              type: 'error',
              position: 'top-center',
              duration: '5000',
              action: {
                text: 'Cancel',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                }
              }
            })
          })
      }
    }
  },

  components: {
    'app-title': () => import('../../../core/Title'),
    'app-btn': () => import('../../../core/BasicBtn')
  },
  directives: { mask }
}
</script>

<style scoped>
.height-100 {
  height: 100vh;
}
.top {
  position: absolute;
  top: 50px;
  right: 50px;
}
.container {
  background-image: url('../../../assets/img/medical-survey.svg');
  background-position: bottom left;
  background-size: 40%;
}
</style>
