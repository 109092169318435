<template>
  <v-container fluid class="pa-0">
    <app-page-title
      title="Saisie formulaire"
      subtitle="Temps de remplissage : ~2-5 minutes"
    >
    </app-page-title>
    <v-container>
      <!-- Stepper de rappel -->
      <v-col col="12" md="7" class="mx-auto">
        <v-stepper v-model="active" class="elevation-0">
          <v-stepper-header>
            <v-stepper-step step="1" editable :rules="[() => !hasErrorForm0]"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" editable :rules="[() => !hasErrorFormA]"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" editable :rules="[() => !hasErrorFormB]"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4" editable :rules="[() => !hasErrorFormC]"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="5" editable :rules="[() => !hasErrorFormD]"></v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>

      <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <p>Chargement des données</p>
      </div>

      <v-form
        v-else
        ref="formulaireCasPatient"
        v-model="isValid"
      >
        <v-stepper v-model="active" vertical class="elevation-0">
          <!-- 0 -->
          <v-stepper-step
            step="1"
            editable
            class="bg-grey font-weight-bold text-uppercase"
            :rules="[() => !hasErrorForm0]"
          >
            Informations préliminaires
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-form ref="stepForm0" v-model="isValidForm0">
              <pickupDateForm
                :pickupDate="formulaire.pickupDate"
              ></pickupDateForm>
              <demographicDataForm :answers="formulaire"></demographicDataForm>
              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateForm0">
                    Continuer
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- A -->
          <v-stepper-step
            step="2"
            :editable="true"
            class="bg-grey font-weight-bold text-uppercase"
            :rules="[() => !hasErrorFormA]"
          >
            Motif de la visite à l’officine
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-form ref="stepFormA" v-model="isValidFormA">
              <visitReasonsForm :isPec=isPec :reasons="formulaire.reason"></visitReasonsForm>
              <whoReferredForm
                :whoReferred="formulaire.whoReferred"
              ></whoReferredForm>
              <accessDifficultyForm
                :accessDifficulty="formulaire.accessDifficulties"
              ></accessDifficultyForm>
              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateFormA">
                    Continuer
                  </v-btn>

                  <v-btn text @click="active = active - 1">
                    Retour
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- B -->
          <v-stepper-step
            step="3"
            :editable="true"
            class="bg-grey font-weight-bold text-uppercase"
            :rules="[() => !hasErrorFormB]"
          >
            Recueil d’informations par le pharmacien (questions)
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-form ref="stepFormB" v-model="isValidFormB">
              <patientHealthStatusForm
                :patientHealthStatus="formulaire.patientHealthStatus"
              ></patientHealthStatusForm>

              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateFormB">
                    Continuer
                  </v-btn>

                  <v-btn text @click="active = active - 1">
                    Retour
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- C -->
          <v-stepper-step
            step="4"
            :editable="true"
            class="bg-grey font-weight-bold text-uppercase"
            :rules="[() => !hasErrorFormC]"
          >
            Actes et prise en charge
          </v-stepper-step>

          <v-stepper-content step="4">
            <v-form ref="stepFormC" v-model="isValidFormC">
              <performedActsForm
                :performedActs="formulaire.performedActs"
              ></performedActsForm>
              <whoTakeCareForm
                :whoTakeCare="formulaire.whoTakeCare"
              ></whoTakeCareForm>
              <whereTakeCareForm
                :whereTakeCare="formulaire.whereTakeCare"
              ></whereTakeCareForm>
              <durationTakeCareForm
                :formulaire="formulaire"
              ></durationTakeCareForm>

              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateFormC">
                    Continuer
                  </v-btn>

                  <v-btn text @click="active = active - 1">
                    Retour
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- D -->
          <v-stepper-step
            step="5"
            :editable="true"
            class="bg-grey font-weight-bold text-uppercase"
            :rules="[() => !hasErrorFormD]"
          >
            Orientation
          </v-stepper-step>

          <v-stepper-content step="5">
            <v-form ref="stepFormD" v-model="isValidFormD">
              <recommendedOrientationForm
                :recommendedOrientation="formulaire.recommendedOrientation"
              ></recommendedOrientationForm>
              <healthProfessionalRelationForm
                :healthProfessionalRelation="
                  formulaire.healthProfessionalRelation
                "
                :display="
                  formulaire.recommendedOrientation.content.includes(
                    'heathprofessionnal'
                  )
                "
              ></healthProfessionalRelationForm>
              <telemedicineToolsForm
                :telemedicineTools="formulaire.telemedicineTools"
                :display="
                  formulaire.recommendedOrientation.subLevelB.subLevel.subLevel
                    .content === 'teleConsultingSide'
                "
              ></telemedicineToolsForm>
              <telemedicineResultsForm
                :telemedicineResults="formulaire.telemedicineResults"
                :display="
                  formulaire.recommendedOrientation.subLevelB.subLevel.subLevel
                    .content === 'teleConsultingSide'
                "
              ></telemedicineResultsForm>
              <sharedInfoForm
                :sharedInfo="formulaire.sharedInfo"
                :display="formulaire.sharedInfo.content == true"
              ></sharedInfoForm>
              <howSharedInfoForm
                :howSharedInfo="formulaire.howSharedInfo"
                :display="formulaire.sharedInfo.content == true"
              ></howSharedInfoForm>
              <specificProtocolForm
                :specificProtocol="formulaire.specificProtocol"
              ></specificProtocolForm>

              <v-row>
                <v-col>
                  <v-btn color="primary" @click="validateFormD">
                    Continuer
                  </v-btn>

                  <v-btn text @click="active = active - 1">
                    Retour
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper>

        <v-row>
          <v-col cols="12" md="3">
            <btn color="primary" large minWidth="240px" @click="update">
              Mettre à jour
            </btn>
          </v-col>
          <v-col cols="12" md="3">
            <btn color="error" large minWidth="240px" :filled="false" @click="$router.push('/historique')">
              Annuler
            </btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import Formulaire from '../../../models/formulaire'
import FormulaireService from '../../../services/formulaire.service'

export default {
  name: 'editFormulairePatient',

  data: function() {
    return {
      formulaire: new Formulaire(),
      loading: true,
      isValid: false,
      active: 0,

      isMainFormValid: false,
      isValidForm0: false,
      hasErrorForm0: false,
      isValidFormA: false,
      hasErrorFormA: false,
      isValidFormB: false,
      hasErrorFormB: false,
      isValidFormC: false,
      hasErrorFormC: false,
      isValidFormD: false,
      hasErrorFormD: false,
    }
  },

  mounted() {
    if (this.$route.params.id) {
      // @todo : Add the same security in the back
      FormulaireService.getFormulaireById(this.$route.params.id).then(
        response => {
          this.formulaire = response.data
          // console.log(response.data)
          this.loading = false
        },
        error => {
          // eslint-disable-next-line no-console
          console.log(error.response)
        }
      )
    }
  },

  components: {
    pickupDateForm: () =>
      import('../components/pickupDateForm'),
    demographicDataForm: () =>
      import('../components/demographicDataForm'),
    visitReasonsForm: () =>
      import('../components/visitReasonsForm'),
    whoReferredForm: () =>
      import('../components/whoReferredForm'),
    accessDifficultyForm: () =>
      import('../components/accessDifficultyForm'),
    patientHealthStatusForm: () =>
      import('../components/patientHealthStatusForm'),
    performedActsForm: () =>
      import('../components/performedActsForm'),
    whoTakeCareForm: () =>
      import('../components/whoTakeCareForm'),
    whereTakeCareForm: () =>
      import('../components/whereTakeCareForm'),
    durationTakeCareForm: () =>
      import('../components/durationTakeCareForm'),
    recommendedOrientationForm: () =>
      import('../components/recommendedOrientationForm'),
    healthProfessionalRelationForm: () =>
      import('../components/healthProfessionalRelationForm'),
    telemedicineToolsForm: () =>
      import('../components/telemedicineToolsForm'),
    telemedicineResultsForm: () =>
      import('../components/telemedicineResultsForm'),
    sharedInfoForm: () =>
      import('../components/sharedInfoForm'),
    howSharedInfoForm: () =>
      import('../components/howSharedInfoForm'),
    specificProtocolForm: () =>
      import('../components/specificProtocolForm'),
    'app-page-title': () => import('../../../core/PageTitle'),
    btn: () => import('../../../core/BasicBtn.vue')
  },

  methods: {
    update() {
      if (this.validateAll()) {
        FormulaireService.putFormulaire(this.formulaire, this.$route.params.id)
          .then(response => {
            this.$toasted.show(response.message, {
              type: 'success',
              duration: '5000',
              position: 'top-center'
            })
            this.$router.go(-1)
          })
          .catch(err => {
            console.log(err)
            this.$toasted.show(err.error, {
              type: 'error',
              position: 'top-center',
              duration: '5000',
              action: {
                text: 'Cancel',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                }
              }
            })
          })
      }
    },
    validateForm0() {
      if (this.$refs.stepForm0.validate()) {
        this.isValidForm0 = true
        this.hasErrorForm0 = false
        this.active = 0
      } else {
        this.hasErrorForm0 = true
      }
    },
    validateFormA() {
      if (this.$refs.stepFormA.validate()) {
        this.isValidFormA = true
        this.hasErrorFormA = false
        this.active = 0
      } else {
        this.hasErrorFormA = true
      }
    },
    validateFormB() {
      if (this.$refs.stepFormB.validate()) {
        this.isValidFormB = true
        this.hasErrorFormB = false
        this.active = 0
      } else {
        this.hasErrorFormB = true
      }
    },
    validateFormC() {
      if (this.$refs.stepFormC.validate()) {
        this.isValidFormC = true
        this.hasErrorFormC = false
        this.active = 0
      } else {
        this.hasErrorFormC = true
      }
    },
    validateFormD() {
      if (this.$refs.stepFormD.validate()) {
        this.isValidFormD = true
        this.hasErrorFormD = false
        this.active = 0
      } else {
        this.hasErrorFormD = true
      }
    },
    validateAll() {
      this.validateForm0()
      this.validateFormA()
      this.validateFormB()
      this.validateFormC()
      this.validateFormD()

      // this.$refs.formulaireCasPatient.validate()
      return this.isValidForm0 && this.isValidFormA && this.isValidFormB && this.isValidFormC && this.isValidFormD
    }
  },
  computed: {
    isPec() {
      return this.formulaire.reason.content.includes("pec")
    }
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
