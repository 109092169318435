import axios from 'axios';
import authHeader from './auth-header';

const API_URL_RESET_PASS = process.env.VUE_APP_URL_API + 'api/resetpassword';
const API_URL_NEW_PASS = process.env.VUE_APP_URL_API + 'api/changepassword';

class PasswordService {
  putEmail (email) {
    // eslint-disable-next-line no-console
    // console.log(process.env.VUE_APP_URL_FRONT);
    return axios
      .put(API_URL_RESET_PASS, {
        email: email,
        url: process.env.VUE_APP_URL_FRONT + 'newpassword/',
        headers: authHeader()
      })
      .catch(err => {
        if (err.response) {
          return err.response;
        } else {
          return err;
        }
      });
  }
  putPassword (password, token) {
    return axios
      .put(API_URL_RESET_PASS + '/new', {
        password: password,
        token: token
      })
      .catch(err => {
        if (err.response) {
          return err.response;
        } else {
          return err;
        }
      });
  }
  newPassword (currentPassword, newPassword) {
    return axios
      .put(API_URL_NEW_PASS, {
        headers: authHeader(),
        currentPassword: currentPassword,
        newPassword: newPassword
      })
      .catch(err => {
        if (err.response) {
          return err.response;
        } else {
          return err;
        }
      })
  }
}



export default new PasswordService();
