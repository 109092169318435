<template>
  <div>
    <radioForm
      title="Le patient a-t-il rencontré, avant son arrivée à l’officine, des difficultés d’accès à un autre professionnel de santé ?"
      :options="accessDifficultyOptions"
      :model="accessDifficulty"
      :level="0"
    >
    </radioForm>
  </div>
</template>

<script>
export default {
  name: 'accessDifficultyForm',
  props: {
    accessDifficulty: { type: Object, required: true }
  },
  components: {
    radioForm: () => import('../../../core/formFactory/radioForm')
  },
  data: function() {
    return {
      accessDifficultyOptions: [
        {
          value: true,
          label: 'Oui',
          sublevel: {
            modelname: 'subLevel',
            type: 'checkbox',
            title: 'Précisez lequel :',
            options: [
              {
                value: 'traitant',
                label: 'Médecin traitant'
              },
              {
                value: 'othergeneralist',
                label: 'Autre médecin généraliste que médecin traitant'
              },
              {
                value: 'specialist',
                label: 'Médecin spécialiste'
              },
              {
                value: 'otherpro',
                label: 'Autre professionnel de santé ',
                sublevel: {
                  modelname: 'subLevel',
                  type: 'checkbox',
                  options: [
                    { value: 'nurse', label: 'Infirmier' },
                    { value: 'kine', label: 'Kinésithérapeute' },
                    { value: 'bioLab', label: 'Laboratoire de Biologie' },
                    { value: 'midwife', label: 'Sage-femme' },
                    { value: 'dentalChir', label: 'Chirurgien-Dentiste' },
                    { value: 'pediPodo', label: 'Pédicure-podologue' },
                    { value: 'other', label: 'Autre', precision: true }
                  ]
                }
              },
              {
                value: 'healthCenter',
                label:
                  'Maison de santé / Centre de santé / Equipe de soins primaires …'
              },
              {
                value: 'hospital',
                label: 'Hôpital'
              },
              {
                value: 'samu',
                label: 'SAMU / Pompiers'
              },
              {
                value: 'regulation',
                label:
                  'Régulation libérale (ex : Numéros spécifiques, SOS médecins, CPTS…) '
              }
            ]
          }
        },
        {
          value: false,
          label: 'Non'
        }
      ]
    }
  }
}
</script>
