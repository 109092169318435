<template>
  <v-container class="pa-0" fluid>
    <page-title
      title="Protocole utilisé en région GRAND EST"
      subtitle="Description des étapes"
      icon="information-outline"
    ></page-title>

    <section>
      <v-container>
        <p class="font-italic">
          Afin de mener à bien cette enquête pour valoriser le rôle du
          pharmacien dans l’orientation, voire l’initiation de la prise en
          charge, des patients avec des demandes de soins non programmés, il est
          essentiel que vous puissiez renseigner dans cet outil les demandes que
          vous recevez actuellement dans votre officine.
          <br />
          <br />
          Vous trouverez ci-dessous les réponses aux questions fréquentes des
          équipes officinales.
          <br />
          Retrouvez également une <a href="/faq">FAQ</a> suite au premier
          webianire.
        </p>
        <p>
          Numéro de la hotline Tous Pour La Santé :
          <a href="tel:0645465846">06 45 46 58 46</a>
        </p>
      </v-container>
    </section>

    <section class="grey lighten-4">
      <v-container>
        <div class="text-center">
          <section-title>Comment faire ? 3 étapes suffisent</section-title>
        </div>
        <li>
          <b>Vous connecter</b> sur la plateforme à l’aide de l’identifiant et
          du mot de passe renseignés lors de votre inscription
        </li>
        <li>
          <b>Cliquer sur la bulle « Saisie de formulaire » </b>en haut à
          droite de votre page d’accueil
        </li>
        <li>
          <b>Remplir le questionnaire </b> avec les informations relatives à la
          demande de soins non programmée que vous avez prise en charge
        </li>
      </v-container>
    </section>

    <section>
      <v-container>
        <div class="text-center">
          <section-title>
            Quels types de demandes enregistrer sur la plateforme ?
          </section-title>
        </div>
        <p>
          <b
            >« Toute demande d’un patient se présentant spontanément à
            l’officine avec une demande de soins répondant à la définition
            suivante :
            <br />
            <br />
            Les soins non programmés en pharmacie sont ceux devant répondre à
            une demande directe ou une urgence ressentie d’un patient. Ils
            permettent de répondre, durant la plage d'ouverture ou de garde des
            officines, aux besoins de soins de la population en proximité dans
            un contexte de démographie médicale souvent tendu (urgentistes,
            médecins libéraux…).
            <br />
            <br />
            Sauf si l’état du patient l’exige, ces soins ne relèvent pas
            médicalement de l’urgence et ne nécessitent pas de prise en charge
            par les services hospitaliers d’accueil des urgences ou par un
            médecin. Le concours de l’officine peut s’inscrire comme effecteur
            dans le parcours de soins non programmés du patient à la demande
            d’un système de régulation. »</b
          >
        </p>

        <ul>
          <li>
            Renseignement des situations, plaintes, demandes de patients :
          </li>
          <ul>
            <li>
              <b>Motif de la visite : </b>domaine où se situe la demande,
              professionnels rencontrés avant le pharmacien
            </li>
            <li>
              <b>Recueil d'information par le pharmacien </b>: antécédents,
              traitements
            </li>
            <li>
              <b>Acte(s) réalisés à l'officine </b>: gestes d'urgence, prise de
              mesures, nettoyage et désinfection de plaie ou brûlure,
              dispensation produit, délivrance conseil ou explication
            </li>
            <li>
              <b>Prise en charge à revoir en fonction de la méthodologie </b>:
              qui a fait quoi et où
            </li>
            <li>
              <b>Recommandations & orientation </b>: devenir du patient,
              orientation et modalités d'orientation
            </li>
          </ul>
          <li>Exemples (non exhaustifs) à titre d'illustrations :</li>
          <ul>
            <li><b>Maux de tête</b>, douleurs dentaires...</li>
            <li>
              Infection urinaire, anxiété, problème ophtalmologique, sevrage
              tabagique...
            </li>
            <li>
              Suspicion <b>d'évènement médical sévère ou grave</b> (Infarctus du
              myocarde, Accident Vasculaire Cérébral, Détresse respiratoire,
              Infections...),
            </li>
            <li><b>Chute de vélo,</b> avec ou sans plaie ouverte,</li>
            <li><b>Entorse</b></li>
            <li><b>Demande d'explication d'un traitement</b>...</li>
          </ul>
          <li>
            <b>Les simples délivrances sur prescription médicale</b> ne sont
            donc pas concernées ! Cependant les demandes en sus d'une délivrance
            d'ordonnance sont à prendre en compte.
          </li>
        </ul>
      </v-container>
    </section>

    <section class="grey lighten-4">
      <v-container>
        <div class="text-center">
          <section-title>
            Combien de demandes faut-il faire remonter par officine ?
          </section-title>
        </div>
        <p>
          <b>Toutes </b>celles qui se présentent durant les
          <b>demi-journées de recueil définies </b>par le calendrier disponible
          dans le protocole et qui répondent à la définition de demandes de
          soins non programmées. (Une rémunération étant prévue dans le
          protocole en fonction du nombre de participations aux demi-journées de
          recueil et en fonction du nombre de données enregistrées durant
          l’étude).
        </p>
      </v-container>
    </section>

    <section>
      <v-container>
        <div class="text-center">
          <section-title>
            Combien de temps demande la participation à l’enquête ? 2 min par
            questionnaire
          </section-title>
        </div>
        <p>
          Du fait du <b>peu de temps dont vous disposez</b>, le questionnaire
          s’adapte à vos réponses et 2 minutes suffisent par cas/ demande
          enregistré.
        </p>
        <p>
          <b>Une version simplifiée du questionnaire papier à imprimer</b> est à
          votre disposition, à télécharger <a href="/pdf/Questionnaire patient à imprimer - Plateforme Soins Non Programmés.pdf" target="_blank" download>ici</a>, vous permet de renseigner les
          différentes informations directement au comptoir et de saisir les
          demandes ultérieurement sur la plateforme.
        </p>
      </v-container>
    </section>

    <section class="grey lighten-4">
      <v-container>
        <div class="text-center">
          <section-title>
            Qui doit renseigner les cas dans la plateforme ?
          </section-title>
        </div>
        <p>
          <b>Tous les membres de l’équipe officinale </b>peuvent renseigner les
          cas/ demandes de patients qu’ils ont gérés directement
        </p>
        <p>
          Vous avez aussi la possibilité de déléguer la saisie des cas sur la
          plateforme à un membre de l’équipe, y compris à un étudiant stagiaire…
        </p>
      </v-container>
    </section>

    <section>
      <v-container>
        <div class="text-center">
          <section-title>Calendrier de recueil de données</section-title>
        </div>
        <app-calendar type="month"></app-calendar>
      </v-container>
    </section>

    <video-presentation-section></video-presentation-section>
  </v-container>
</template>

<script>
export default {
  name: 'InformationsComplementaires',

  components: {
    // 'aaaa': () => import('../../../core/aaaa.vue'),
    'page-title': () => import('../../../core/PageTitle.vue'),
    'section-title': () => import('../../../core/Title.vue'),
    'app-calendar': () => import('../../../core/Calendar.vue'),
    'video-presentation-section': () => import('../../home/components/VideoPresentationSection')
  }
}
</script>

<style scoped>
section {
  padding: 20px 0;
}
</style>
