<template>
  <div>
    <checkbox-form
      title=" Motifs de la visite à l’officine du patient"
      :options="concatedReasonList"
      :model="reasons"
      :level="0"
    ></checkbox-form>
  </div>
</template>

<script>
export default {
  name: 'visitReasonsForm',

  props: {
    reasons: { type: Object, required: true },
    isPec: { type: Boolean }
  },

  data: function() {
    return {
      expandliberal: false,
      reasonsListStart: [
        {
          value: 'cardio',
          label:
            'Cardiovasculaire (problème circulation : Jambes lourdes/ hémorroïdes, problème cardiaque : palpitations…)'
        },
        { value: 'dental', label: 'Dentaire (problème infectieux, douleur…)' },
        {
          value: 'dermato',
          label:
            'Dermatologie (plaie, piqûre d’insecte/ de tique, allergie : urticaire/ démangeaisons, infections cutanées…)'
        },
        { value: 'diabete', label: 'Diabète' },
        {
          value: 'gastro',
          label: 'Gastro-entérologie/ Digestif (constipation, diarrhée…)'
        },
        {
          value: 'gyneco',
          label: 'Gynécologie (mycose vaginale, sécheresse…)'
        },
        {
          value: 'infectio',
          label:
            'Infectiologie/ Fièvre (grippe, zona, erysipèle, parasitologie…)'
        },
        {
          value: 'ophtalmo',
          label: 'Ophtalmologie (zona, coup d’arc, conjonctivite, œil rouge…)'
        },
        {
          value: 'orl',
          label:
            'ORL (toux, maux de gorge, rhinite allergique, problème d’oreille…)'
        },
        {
          value: 'pneumo',
          label:
            'Pneumologie/Respiratoire (difficultés respiratoires, crise d’asthme…)'
        }
      ],
      reasonListEnd: [
        {
          value: 'psycho',
          label:
            'Psychologie (stress, anxiété, dépression, addiction, boulimie…)'
        },
        {
          value: 'tabac',
          label: 'Tabac (initiation d’une démarche de sevrage tabagique)'
        },
        {
          value: 'trauma',
          label:
            'Traumatologie (choc, gonflement, entorse, hématome, suspicion fracture…)'
        },
        {
          value: 'uro',
          label:
            'Urologie/ Urinaire (infection urinaire, difficultés à uriner…)'
        },
        { value: 'other', label: 'Autres', precision: true }
      ],
      oldPec: [
        {
          value: 'pec',
          label: 'Prise en charge de signes évocateurs de la Covid-19'
        }
      ],
      newPec: [
        {
          value: 'pec_sign',
          label: 'Prise en charge de signes évocateurs de la COVID-19 & tests antigéniques (Cas contacts, patients symptomatiques, prescription médicale)'
        },
        {
          value: 'pec_comfort',
          label: 'Tests antigéniques de confort (à la demande du patient, asymptomatique et sans contact avec une personne positive)'
        }
      ]
    }
  },
  computed: {
    concatedReasonList() {
      return this.reasonsListStart.concat(this.isPec ? this.oldPec : this.newPec).concat(this.reasonListEnd)
    }
  },
  components: {
    'checkbox-form': () => import('../../../core/formFactory/checkboxesForm')
  }
}
</script>

<style scoped></style>