import Vue from 'vue'
import Router from 'vue-router'
import Home from './modules/home/view/Home.vue'
import Login from './modules/login/view/Login.vue'
import Logout from './modules/logout/view/Logout.vue'
import Register from './modules/register/view/Register.vue'
import Confirmation from './modules/confirmation/view/Confirmation.vue'
import ResetPassword from './modules/password/view/ResetPassword.vue'
import NewPassword from './modules/password/view/NewPassword.vue'
import ChangePassword from './modules/password/view/ChangePassword.vue'
import Profile from './modules/profile/view/Profile.vue'
import EditUser from './modules/register/view/EditUser.vue'
import notFound from './core/notFound.vue'
import Admin from './modules/admin/view/Admin.vue'
import DashboardAdmin from './modules/admin/view/DashboardAdmin.vue'
import formulairePatient from './modules/patient/view/formulairePatient.vue'
import editFormulairePatient from './modules/patient/view/editFormulairePatient.vue'
import Historique from './modules/history/view/Historique.vue'
import HistoriqueAdmin from './modules/history/view/HistoriqueAdmin.vue'
import UsersAdmin from './modules/user/view/UsersAdmin.vue'
import Mention from './modules/mention/view/Mention.vue'
import InformationsComplementaires from './modules/mention/view/InformationsComplementaires.vue'
import FrequentlyAskedQuestions from './modules/mention/view/FrequentlyAskedQuestions.vue'

Vue.use(Router)
export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '*',
      component: notFound
    },
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'no-border' },
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      meta: { layout: 'no-border' },
      component: Logout
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/confirmation/:token',
      name: 'confirmation',
      meta: { layout: 'no-border' },
      component: Confirmation
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      meta: { layout: 'no-border' },
      component: ResetPassword
    },
    {
      path: '/newpassword/:token',
      name: 'newpassword',
      meta: { layout: 'no-border' },
      component: NewPassword
    },
    {
      path: '/changepassword/',
      name: 'changepassword',
      component: ChangePassword
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile
    },
    {
      path: '/edituser',
      name: 'edituser',
      component: EditUser
    },
    {
      path: '/admin',
      component: Admin,
      children: [
        {
          path: 'dashboard',
          name: 'admin-page',
          component: DashboardAdmin,
          meta: { layout: 'sidebar' }
        },
        {
          path: 'historique',
          name: 'historique-admin',
          component: HistoriqueAdmin,
          meta: { layout: 'sidebar' }
        },
        {
          path: 'users',
          name: 'historique-users',
          component: UsersAdmin,
          meta: { layout: 'sidebar' }
        }
      ]
    },
    {
      path: '/formulaire',
      name: 'formulaire',
      component: formulairePatient
    },
    {
      path: '/edit-formulaire/:id',
      name: 'editFormulaire',
      component: editFormulairePatient
    },
    {
      path: '/historique',
      name: 'historique',
      component: Historique
    },
    {
      path: '/mention',
      name: 'mention',
      component: Mention
    },
    {
      path: '/information',
      name: 'information',
      component: InformationsComplementaires
    },
    {
      path: '/faq',
      name: 'faq',
      component: FrequentlyAskedQuestions
    },
    {
      path: '/integration',
      name: 'integration',
      meta: { layout: 'no-border' },
      component: () => import('./modules/integration/view/Integration.vue')
    }
  ]
})
