<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-card elevation-2 width="50%">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Changer le mot de passe</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="isValid" :lazy-validation="lazy">
            <v-text-field
              class=""
              v-model="currentPassword"
              :rules="required"
              label="Mot de passe actuel"
              required
              outlined
              :append-icon="currentPassword_display ? 'mdi-eye' : 'mdi-eye-off'"
              :type="currentPassword_display ? 'text' : 'password'"
              @click:append="currentPassword_display = !currentPassword_display"
            ></v-text-field>
            <v-text-field
              class=""
              v-model="newPassword"
              :rules="required"
              label="Nouveau mot de passe"
              required
              outlined
              :append-icon="newPassword_display ? 'mdi-eye' : 'mdi-eye-off'"
              :type="newPassword_display ? 'text' : 'password'"
              @click:append="newPassword_display = !newPassword_display"
            ></v-text-field>
            <v-text-field
              class=""
              v-model="reNewPassword"
              :rules="[passwordConfirmationRule]"
              label="Confirmer le nouveau mot de passe"
              required
              outlined
              :append-icon="reNewPassword_display ? 'mdi-eye' : 'mdi-eye-off'"
              :type="reNewPassword_display ? 'text' : 'password'"
              @click:append="reNewPassword_display = !reNewPassword_display"
            ></v-text-field>

            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{}">
                <v-btn
                  color="primary"
                  class="mr-4 mt-4"
                  @click="validate"
                  :disabled="!isValid"
                >
                  Changer le mot de passe
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline red lighten-1 white--text" v-if="messageKo">
                  {{ messageKo }}
                </v-card-title>

                <v-card-title class="headline primary white--text" v-if="messageOk">
                  {{ messageOk }}
                </v-card-title>
                <br />

                <v-card-text v-if="messageKo">
                  Vérifiez que vous avez bien saisie votre mot de passe.<br />
                  Cliquez sur réesayer pour changer de nouveau votre mot de
                  passe.
                </v-card-text>

                <v-card-text v-if="messageOk">
                  Félicitations, votre mot de passe a bien été changé.<br />
                  Cliquez sur continuer pour revenir sur la page de votre
                  compte.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="messageKo" text outlined @click="retry">
                    Réessayer
                  </v-btn>
                  <v-btn v-if="messageOk" text outlined @click="done">
                    Continuer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import PasswordService from '../../../services/password.service'
export default {
  data: () => ({
    dialog: false,
    isValid: true,
    currentPassword: '',
    newPassword: '',
    reNewPassword: '',
    lazy: '',
    messageOk: '',
    messageKo: '',
    required: [v => !!v || 'Champ requis'],
    currentPassword_display: false,
    newPassword_display: false,
    reNewPassword_display: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.reNewPassword ||
        'Les mots de passe doivent correspondre'
    }
  },
  mounted() {
    if (!this.$store.state.auth.user) {
      this.$router.push('/login')
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        PasswordService.newPassword(
          this.currentPassword,
          this.newPassword
        )
          .then(response => {
            if (response.status == 201) {
              localStorage.setItem('user', JSON.stringify(response.data.user))
              //   localStorage.setItem('token', response.data.token);
              this.messageOk = response.data.message
              this.messageKo = ''
              this.dialog = true
            } else {
              this.messageKo = response.data.message
              this.messageOk = ''
              this.dialog = true
            }
          })
          .catch()
          this.newPassword ='',
          this.reNewPassword='',
          this.currentPassword=''
      }
    },
    retry() {
      this.dialog = false
      // this.$router.push('/resetpassword');
    },
    done() {
      this.dialog = false
      this.$router.push('/profile')
    }
  }
}
</script>
