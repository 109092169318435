<template>
  <div>
    <radioForm
      title="Qui a adressé le patient à l’officine ?"
      :options="whoReferredOptions"
      :model="whoReferred"
      :level="0"
    >
    </radioForm>
  </div>
</template>

<script>
export default {
  name: 'whoReferredForm',
  props: {
    whoReferred: { type: Object, required: true }
  },
  components: {
    radioForm: () => import('../../../core/formFactory/radioForm')
  },
  data: function() {
    return {
      whoReferredOptions: [
        {
          value: 'spontaneous',
          label: 'Le patient est venu spontanément à l’officine'
        },
        {
          value: 'recommendation',
          label: 'Recommandation d’un proche'
        },
        {
          value: 'healthProfessional',
          label: 'Professionnel de santé libéral',
          sublevel: {
            modelname: 'subLevel',
            type: 'checkbox',
            options: [
              {
                value: 'generalist',
                label: 'Médecin généraliste'
              },
              {
                value: 'specialist',
                label: 'Médecin spécialiste',
                precision: true,
                precname: 'precisiondoctor'
              },
              {
                value: 'nurse',
                label: 'Infirmier'
              },
              {
                value: 'kine',
                label: 'Kinésithérapeute'
              },
              {
                value: 'midwife',
                label: 'Sage-femme'
              },
              {
                value: 'dentalChir',
                label: 'Chirurgien-Dentiste'
              },
              {
                value: 'pediPodo',
                label: 'Pédicure-podologue'
              },
              {
                value: 'otherLiberalOption',
                label: 'Autre',
                precision: true
              }
            ]
          }
        },
        {
          value: 'emergencyservice',
          label: 'Service de régulation/Urgence/Appel de secours',
          sublevel: {
            modelname: 'subLevel',
            type: 'checkbox',
            options: [
              { value: 'samu', label: 'SAMU' },
              { value: 'firemen', label: 'Pompiers' },
              {
                value: 'libreg',
                label:
                  'Régulation libérale (ex : Numéros spécifiques, SOS médecins, …) '
              },
              { value: 'cpts', label: 'CPTS' },
              { value: 'otherservices', label: 'Autre', precision: true }
            ]
          }
        },
        {
          value: 'otherreferrer',
          label: 'Autre',
          precision: true
        }
      ]
    };
  }
};
</script>
