<template>
  <v-container fluid class="grey lighten-4">
    <v-row class="px-4">
      <v-col cols="12" md="6">
        <dep-card></dep-card>
      </v-col>

      <v-col cols="12" md="6">
        <percent-of-covid-card></percent-of-covid-card>

        <inscriptions-card class="mt-6"></inscriptions-card>

        <formulaire-count-card class="mt-6"></formulaire-count-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormulaireService from '../../../services/formulaire.service'

export default {
  name: 'DashboardAdmin',

  components: {
    'percent-of-covid-card': () => import('../components/PercentCovidCard'),
    'dep-card': () => import('../components/DepCard'),
    'inscriptions-card': () => import('../components/InscriptionsCard'),
    'formulaire-count-card': () => import('../components/FormulaireCountCard')
  },

  data: () => ({
    formulaires: []
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    isRoleAdmin() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Administrateur')
      }
      return false
    }
  }
}
</script>
