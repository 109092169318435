<template>
  <v-container fluid>
    <v-container>
      <history v-if="isAdmin"></history>
      <div v-else>Vous n'êtes pas autorisés à voir cette page</div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'HistoriqueAdmin',

  components: {
    history: () => import('../components/historyTable'),
    'app-page-title': () => import('../../../core/PageTitle')
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Administrateur')
      }
      return false
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }
  }
}
</script>
