<template>
  <v-container>
    <v-form ref="formRegisterInfo" v-model="valid" lazy-validation>
      <v-row>
        <!--Identifiant FINESS-->
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            required
            label="Identifiant FINESS"
            name="finess"
            v-model="user.finess"
            v-mask="'#########'"
            prepend-icon="mdi-card-account-details"
            type="number"
            :rules="finessRules"
            outlined
            :counter="9"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            label="Code postal"
            v-model="user.postal"
            v-mask="'#####'"
            type="text"
            :rules="postalRules"
            prepend-icon="mdi-city"
            outlined
            :counter="5"
          ></v-text-field>
        </v-col>

        <!--Adresse Email-->
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            label="Adresse email"
            name="email"
            v-model="user.email"
            :rules="emailRules"
            type="email"
            prepend-icon="@"
            outlined
          ></v-text-field>
        </v-col>

        <!--Password-->
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            label="Mot de passe"
            v-model="user.password"
            name="password"
            prepend-icon="mdi-lock"
            :rules="rules"
            :append-icon="password_display ? 'mdi-eye' : 'mdi-eye-off'"
            :type="password_display ? 'text' : 'password'"
            @click:append="password_display = !password_display"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn color="primary" @click="isValidForm">
        Etape suivante
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  props: ['user', 'active'],

  data() {
    return {
      valid: true,
      activeProps: 0,
      rules: [v => !!v || 'Champ requis'],
      finessRules: [
        v => !!v || 'Numéro Finess requis',
        v =>
          (v && v.length === 9) || 'Le numéro finess doit être de 9 caractère'
      ],
      postalRules: [
        v => !!v || 'Un code postal est requis',
        v => (v && v.length === 5) || 'Code postal de 5 caractère requis'
      ],
      emailRules: [
        v => !!v || 'E-mail est requis',
        v => /.+@.+\..+/.test(v) || 'E-mail doit être valid'
      ],
      password_display: false
    }
  },
  methods: {
    isValidForm() {
      if (this.$refs.formRegisterInfo.validate()) {
        this.activeProps = this.active
        this.activeProps++
        this.$emit('formregisterinfo', {
          active: this.activeProps,
          valid: true
        })
      }
    }
  },
  directives: { mask }
}
</script>
