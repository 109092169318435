<template>
  <div>
    <checkboxForm
      title="Par quel(s) moyen(s) avez-vous partagé cette information ?"
      :options="howSharedInfoOptions"
      :model="howSharedInfo"
      :level="0"
      v-if="display"
    ></checkboxForm>
  </div>
</template>

<script>
export default {
  name: 'howSharedInfoForm',
  props: {
    howSharedInfo: { type: Object, required: true },
    display: { type: Boolean, required: true }
  },
  components: {
    checkboxForm: () => import('../../../core/formFactory/checkboxesForm')
  },
  data: function() {
    return {
      howSharedInfoOptions: [
        {
          value: 'letter',
          label: 'Courrier',
          sublevel: {
            modelname: 'subLevel',
            type: 'radio',
            options: [
              {
                value: 'viaPatient',
                label: 'Courrier transmis par l’intermédiaire du patient'
              },
              {
                value: 'directly',
                label: 'Courrier transmis directement au professionnel'
              }
            ]
          }
        },
        {
          value: 'mail',
          label: 'Mail',
          sublevel: {
            modelname: 'subLevel',
            type: 'radio',
            options: [
              { value: 'secure', label: 'Messagerie sécurisée' },
              { value: 'normal', label: 'Email traditionnel' }
            ]
          }
        },
        {
          value: 'phone',
          label: 'Appel téléphonique'
        },
        {
          value: 'sms',
          label: 'SMS / Messagerie instantanée'
        },
        {
          value: 'oral',
          label: 'Oral'
        }
      ]
    }
  }
}
</script>
