<template>
  <v-container fluid class="ma-0 pa-0">
    <page-title
      :title="'Edition du profil'"
      :subtitle="'Modifier vos informations'"
    ></page-title>
    <v-container>
      <v-stepper v-model="active" vertical class="elevation-0">
        <v-stepper-step
          :complete="active > 1"
          step="1"
          :editable="active > 1"
          class="bg-grey font-weight-bold text-uppercase"
        >
          Description de l’officine
        </v-stepper-step>

        <v-stepper-content step="1">
          <Description
            :user="user"
            :active="active"
            :editionMode="editionMode"
            @formdescription="getValidationForDescription"
          ></Description>
        </v-stepper-content>

        <v-stepper-step
          :complete="active > 2"
          step="2"
          :editable="active > 2"
          class="bg-grey font-weight-bold text-uppercase"
        >
          Qualification du titulaire
        </v-stepper-step>

        <v-stepper-content step="2">
          <Qualification
            :user="user"
            :active="active"
            @formqualification="getValidationForQualification"
          ></Qualification>
        </v-stepper-content>
      </v-stepper>
      <!-- Submit -->
      <v-spacer></v-spacer>
      <v-row>
        <!--Conditions-->
        <v-col cols="12" md="6">
          <v-btn
            :disabled="!this.validForDescription || !this.validForQualification"
            color="success"
            class="mr-4"
            @click="dialog = true"
            >Edition</v-btn
          >
          <v-dialog
            v-model="dialog"
            persistent
            max-width="290"
            scrollable
            v-if="!this.condition"
          >
            <v-card>
              <v-card-title class="headline">
                Confirmation
              </v-card-title>
              <v-card-text
                >Êtes-vous sûr de vouloir modifier votre profile ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="
                    dialog = false
                    condition = false
                  "
                >
                  Non
                </v-btn>
                <v-btn color="green darken-1" text @click="validate">
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import User from '../../../models/user'
import Description from '../components/Description'
import Qualification from '../components/Qualification'
import UserService from '../../../services/user.service'

export default {
  name: 'EditUser',
  data() {
    return {
      user: new User(null),
      rules: {
        required: value => !!value || 'Champ requis.'
      },
      password_display: false,
      situation: '',
      type: '',
      active: 1,
      dialog: false,
      condition: false,
      validForDescription: false,
      validForQualification: false,
      editionMode: true
    }
  },
  components: {
    Description,
    Qualification,
    'page-title': () => import('../../../core/PageTitle.vue')
  },
  mounted() {
    this.user = this.$store.state.auth.user
    if (!this.user) this.$router.push('/login')
  },
  methods: {
    validate() {
      this.dialog = false
      this.condition = true
      if (
        this.condition &&
        this.validForDescription &&
        this.validForQualification
      ) {
        this.handleRegister()
      }
    },
    handleRegister() {
      // console.log(this.user._id)
      this.$store
        .dispatch('auth/update', this.user)
        .then(response => {
          this.$toasted.show(response.message, {
            type: 'success',
            duration: '5000',
            position: 'top-center'
          })
        })
        .catch(err => {
          this.$toasted.show(err, {
            type: 'error',
            position: 'top-center',
            duration: '5000',
            action: {
              text: 'Cancel',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              }
            }
          })
        })
      this.$router.push('/profile')
    },
    getValidationForDescription(value) {
      if (value.hasOwnProperty('valid')) {
        this.validForDescription = value.valid
      }
      this.active = value.active
    },
    getValidationForQualification(value) {
      if (value.hasOwnProperty('valid')) {
        this.validForQualification = value.valid
      }
      this.active = value.active
    }
  }
}
</script>

<style>
.bg-grey {
  background-color: rgb(223, 223, 223);
}
</style>
